import styled, { keyframes } from "styled-components";

const Textarea = styled.textarea`
  -webkit-appearance: none;
  position: relative;
  width: 100%;
  height: 35px;
  background-color: ${({isWebinar}) => isWebinar ? '#FFFFFF34' : '#fff'};
  resize: none;
  border-radius: 2px;
  padding: 5px 32px 5px 5px;
  color: ${({isWebinar}) => isWebinar ? '#FFFFFFdd' : 'rgb(51, 51, 51)'};
  line-height: 22px;
  z-index: 1;
  ${({isWebinar, isPreview}) => isWebinar ? `outline: none; border: none; border-radius: 5px; padding: 5px ${isPreview ? '35px' : '70px'} 5px 5px;` : ''};
  &::placeholder {
    color: ${({isWebinar}) => isWebinar ? '#999999' : 'inherit'};
  }
  font-size: 16px;
`;

const InputWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding-bottom: ${({isWebinar}) => isWebinar ? '20px' : '0px'};
  svg {
    color: ${({isWebinar}) => isWebinar ? '#999999' : '#bfbfbf'};
  }
`;

const Button = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  right: ${({fileButton}) => fileButton ? '30px' : 0};
  font-size: 23px;
  ${({isWebinar}) => isWebinar && `
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 3px;
    padding-left: 0;
    height: 35px;
    width: 35px;
    position: static;
  `};
`;

const ErrorPopup = styled.div`
  transition-property: opacity, bottom, left, right, width, margin, border-radius;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  font-size: 14px;
  min-height: 14px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #721c24;
  width: 100%;
  line-height: 22px;
  padding: 18px 24px;
  border-radius: 2px;
  z-index: 10;
  transform: translateY(-100%);

  svg {
    color: #721c24;
    top: 4px;
    right: 5px;
    position: absolute;
    cursor: pointer;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spiner = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid black;
    border-color: black transparent black transparent;
    animation: ${rotate} 1.2s linear infinite;
  }
`;

const Img = styled.img`
  height: 24px;
  width: 24px;
  filter: invert(70%) sepia(18%) saturate(9%) hue-rotate(323deg) brightness(83%) contrast(96%);
`;

export default {
    Spiner,
    Button,
    ErrorPopup,
    InputWrapper,
    Textarea,
    Img
}
