import React from 'react';
import styled from "styled-components";

const CloseBtn = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  font-weight: normal;
  color: ${pr => pr.color ? pr.color : 'rgb(153, 153, 153)'};
  
  &:hover {
    color: ${pr => pr.hoverColor ? pr.hoverColor : 'rgb(115, 115, 115)'};
  }
  &:focus {
    color: ${pr => pr.focusColor ? pr.focusColor : 'rgb(115, 115, 115)'};
    font-weight: bold;
  }
  &:active {
    color: ${pr => pr.activeColor ? pr.activeColor : 'rgb(115, 115, 115)'};
    font-weight: bold;
  }
`;

const CloseXButton = (props) => (
    <CloseBtn {...{...props}}>&times;</CloseBtn>
);

export default CloseXButton;
