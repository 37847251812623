import React from "react";

const MaxMemberInfoBlock = () => {
    return (
        <p>
            Oops, this room is at full occupancy. Do you by chance already have this room open in another tab or window?
        </p>
    )
};

export default MaxMemberInfoBlock