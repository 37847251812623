import types from "../constans";

export const emptyEvent = {
    eventId: '',
    organizerProfileId: '',
    name: '',
    description: '',
    backgroundImage: null,
    defaultVirtualBackground: null,
    startDateAndTime: '',
    internalEndDateAndTime: '',
    numberOfSessions: '',
    minPerSession: '',
    sessionFormat: types.SESSION_FORMAT_1TON,
    sessionFormatRematch: types.SESSION_FORMAT_1TON,
    micOnly: false,
    algorithm: '',
    profileElements: '',
    defaultBackgroundImage: '',
    breakImage: '',
    intersessionSurvey: '',
    primaryButtonHex: '',
    primaryButtonClickHex: '',
    primaryButtonDisabledHex: '',
    primaryButtonHoverHex: '',
    primaryButtonTextHex: '',
    accountName: '',
    accountDomain: '',
    extendCallEnabled: 0,
    objectives: '',
    eventTickets: [],
    individualMatching: false,
    eventTags: {},
    hasKickoffVideo: false,
    kickoffVideoLabel: '',
    showWelcomeToMeetaway: false,
    hasChatSupport: false,
    organizerEmail: '',
    organizerName: '',
    privacySetting: '',
    show1MinNotice: true,
    sponsorLogos: [],
    enableTranslation: false,
    organizer: {},
    publishedStatus: '',
    registrationRequired: true,
    isSalesFree: false,
    isInperson: false,
    pwdNotRequired: false,
    hosts: [],
    speakers: [],
    isStartedWebinar: false,
    isLoading: false,
    isDisplayProfile: false,
};

export const emptySession = {
    numberUsers: '',
    userId: '',
    otherUserAId: '',
    otherUserBId: '',
    roomNumber: '',
    sessionEndTime: '',
    sessionNumber: 0,
    sessionStartTime: '',
    showBreakPage: '',
    styleMatch: '',
    videoUrl: '',
    wasDisconnected: false,
    sessionCompleted: '',
    videoCompleted: '',
    videoConnected: '',
    didConnected: '',
    bothConnected: 0,
};

const initialState = {
    appMode: types.EVENT_MODE,
    allDataProvided: false,
    containerLoaded: false,
    bubbleUrl: '',
    directMode: false,
    isReady: false,
    screenView: 'loading',
    fishbowlView: '',
    sidebarTab: 1,
    surveySubmitButton: 'next',
    previousSessionNum: null,
    currentSession: emptySession,
    currentEvent: emptyEvent,
    isPersonIconVisible: false,
    disconnected: false,
    accessGrantedErrorBlock: false,
    accessGrantedErrorBlockReasons: {
        mediaAccessDenied: false,
        webcamBlocked: false
    },
    disconnectedReasons: {
        network: false,
        leftActive: false,
        endCall: false,
        changeDevice: false
    },
    infoBlock: false,
    infoBlockReasons: {
        conLow: false,
        conLowOther: false,
        browserReloaded: false
    },
    maxMemberBlock: false,
    errorInfoBlock: false,
    errorInfoBlockReasons: {
        common: false,
        accessDenied: false,
        noConnection: false,
        networkError: false,
        networkWarning: false
    },
    deviceChanging: false,
    screenShareCancelled: false,
    videoDevice: null,
    audioDevice: null,

    showFoundRightFlyIn: false,
    showNextSessionPopup: false,
    showRightFlyInPopup: false,
    show3rdPartyPopup: false,
    showFishbowlPopup: false,
    showExtendCallPopup: false,
    showSurveyPopup: false,
    showNextPartnerBioPopup: false,
    hideNextPartnerBioPopup: false,
    needShowRightFlyin: true,
    hideSurveyPopup: false,
    needShowSurveyPopup: false,
    nextSessionVariant: null,
    sessionHasBeenExtended: false,
    hideJoinBy3rdParty: false,
    showWrapupNotesPopup: false,
    hideWrapupNotesPopup: false,
    showWrapupEmailPopup: false,
    hideWrapupEmailPopup: false,
    hideEmailPopup: false,
    emailPopupMode: '',
    extendCallBlocked: false,
    extendCallDirectMode: false,
    extendCallByMinutes: null,
    partyView: null,
    acceptedInvite: [],
    surveySuccessfullyPosted: false,
    needSocketReconnect: false,
    isPublishProblems: false,
    showGreenScreen: true,
    emailPopupData: {},
    differenceBetweenServerAndClientTime: 0,
    showEditBreaksPopup: false,
    userSessions: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.REMOVE_EVENT_LOGO :
            return {
                ...state,
                currentEvent: {
                    ...state.currentEvent,
                    sponsorLogos: state.currentEvent.sponsorLogos.filter(logo => logo !== action.logoUrl)
                }
            };
        case types.ADD_EVENT_LOGO :
            return {
                ...state,
                currentEvent: {
                    ...state.currentEvent,
                    sponsorLogos: [...state.currentEvent.sponsorLogos, action.logoUrl]
                }
            };
        case types.UPDATE_CURRENT_EVENT :
            return {
                ...state,
                currentEvent: {...state.currentEvent, ...action.updatedCurrentEvent}
            };
        case types.SET_APP_MODE :
            return {...state, appMode: action.appMode};
        case types.SET_DIRECT_MODE :
            return {...state, directMode: action.directMode};
        case types.SET_BUBBLE_URL :
            return {...state, bubbleUrl: action.bubbleUrl};
        case types.ALL_DATA_PROVIDED :
            return {...state, allDataProvided: action.allDataProvided};
        case types.SET_CONTAINER_LOADED :
            return {...state, containerLoaded: true};

        case types.INIT_ROOM_CONTROLLER_SUCCESS :
            return {...state, isReady: true};

        case types.SET_ACCESS_GRANTED_ERROR_BLOCK_VISIBILITY :
            return {
                ...state,
                accessGrantedErrorBlock: action.isVisible,
                accessGrantedErrorBlockReasons: action.reasons
            };
        case types.SET_DISCONNECTED_INFO :
            return {...state, disconnected: action.disconnected, disconnectedReasons: action.reasons};
        case types.SET_INFO_BLOCK_VISIBILITY :
            return {...state, infoBlock: action.isVisible, infoBlockReasons: action.reasons};
        case types.SET_ERROR_INFO_BLOCK_VISIBILITY :
            return {...state, errorInfoBlock: action.isVisible, errorInfoBlockReasons: action.reasons};
        case types.SET_MAX_MEMBERS_BLOCK_VISIBILITY :
            return {...state, maxMemberBlock: action.isVisible};
        case types.SET_PERSON_ICON_VISIBLE :
            return {...state, isPersonIconVisible: action.isVisible};
        case types.SET_DEVICE_CHANGING :
            return {...state, deviceChanging: action.deviceChanging};
        case types.SET_SCREEN_SHARE_CANCELLED :
            return {...state, screenShareCancelled: action.screenShareCancelled};
        case types.SET_DEVICE:
            return {...state, [action.payload.deviceType]: action.payload.value};
        case types.SWITCH_CURRENT_VIEW:
            return action.reInit ? {
                ...state,
                screenView: action.view,
                isReady: false,
                containerLoaded: false
            } : {...state, screenView: action.view};
        case types.SWITCH_FISHBOWL_VIEW:
            return {...state, fishbowlView: action.fishbowlView};
        case types.UNSET_FISHBOWL_VIEW:
            return {...state, fishbowlView: ''};
        case types.SET_SIDEBAR_TAB:
            return {...state, sidebarTab: action.sidebarTab};
        case types.SET_SURVEY_SUBMIT_BUTTON:
            return {...state, surveySubmitButton: action.button};
        case types.SET_SURVEY_SUCCESSFULLY_POSTED:
            return {...state, surveySuccessfullyPosted: action.flag};
        case types.SET_CURRENT_EVENT:
            return {...state, currentEvent: action.event};
        case types.SET_PREVIOUS_SESSION_NUM:
            return {...state, previousSessionNum: action.previousSessionNum};
        case types.SET_CURRENT_USER_SESSION:
            return {...state, currentSession: action.userSession};
        case types.UPDATE_SESSION_END_TIME :
            return {...state, currentSession: {...state.currentSession, sessionEndTime: action.sessionEndTime}};
        case types.SESSION_HAS_BEEN_EXTENDED :
            return {...state, sessionHasBeenExtended: true};
        case types.SHOW_NEXT_SESSION_POPUP:
            return {...state, showNextSessionPopup: action.isVisible};
        case types.SHOW_RIGHT_FLYIN_POPUP:
            return {...state, showRightFlyInPopup: action.isVisible};
        case types.SHOW_FOUND_RIGHT_FLYIN:
            return {...state, showFoundRightFlyIn: action.isVisible};
        case types.SHOW_3RD_PARTY_POPUP:
            return {...state, show3rdPartyPopup: action.isVisible};
        case types.SHOW_FISHBOWL_POPUP:
            return {...state, showFishbowlPopup: action.isVisible};
        case types.SHOW_SURVEY_POPUP:
            return {...state, showSurveyPopup: action.isVisible, hideSurveyPopup: false};
        case types.SHOW_NEXT_PARTNER_BIO_POPUP:
            return {...state, showNextPartnerBioPopup: action.isVisible, hideNextPartnerBioPopup: false};
        case types.HIDE_NEXT_PARTNER_BIO_POPUP:
            return {...state, hideNextPartnerBioPopup: true};
        case types.SHOW_EXTEND_CALL_ACTION:
            return {...state, showExtendCallPopup: action.isVisible};
        case types.NEED_SHOW_RIGHT_FLYIN:
            return {...state, needShowRightFlyin: action.flag};
        case types.NEED_SHOW_SURVEY_POPUP:
            return {...state, needShowSurveyPopup: action.flag};
        case types.HIDE_SURVEY_POPUP:
            return {...state, hideSurveyPopup: true};
        case types.SHOW_WRAPUP_NOTES_POPUP:
            return {...state, showWrapupNotesPopup: action.visible, hideWrapupNotesPopup: false};
        case types.HIDE_WRAPUP_NOTES_POPUP:
            return {...state, hideWrapupNotesPopup: true};
        case types.SHOW_WRAPUP_EMAIL_POPUP:
            return {...state, showWrapupEmailPopup: action.visible, hideWrapupEmailPopup: false};
        case types.HIDE_WRAPUP_EMAIL_POPUP:
            return {...state, hideWrapupEmailPopup: true};
        case types.SHOW_EMAIL_POPUP:
            return {...state, showEmailPopup: action.visible, emailPopupMode: action.mode, hideEmailPopup: false};
        case types.HIDE_EMAIL_POPUP:
            return {...state, hideEmailPopup: true};
        case types.SET_EMAIL_POPUP_DATA:
            return {...state, emailPopupData: {...state.emailPopupData, [action.key]: action.payload}};

        case types.HIDE_JOIN_BY_3RD_PARTY:
            return {...state, hideJoinBy3rdParty: action.flag};
        case types.SET_SELECTED_NEXT_SESSION_VARIANT:
            return {...state, nextSessionVariant: action.variant};
        case types.SET_VIDEO_CONNECTED:
            return {...state, currentSession: {...state.currentSession, videoConnected: true}};
        case types.SET_VIDEO_BOTH_CONNECTED:
            return {...state, currentSession: {...state.currentSession, bothConnected: true}};
        case types.SET_VIDEO_DID_CONNECTED:
            return {...state, currentSession: {...state.currentSession, didConnected: action.flag}};
        case types.MARK_VIDEO_COMPLETED:
            return {...state, currentSession: {...state.currentSession, videoCompleted: true}};
        case types.MARK_SESSION_COMPLETED:
            return {
                ...state,
                currentSession: {...state.currentSession, sessionCompleted: true},
                sessionHasBeenExtended: false
            };
        case types.MARK_SESSION_DISCONNECTED:
            return {...state, currentSession: {...state.currentSession, wasDisconnected: true}};

        case types.USER_ACCEPT_INVITE:
            return {
                ...state,
                acceptedInvite: state.acceptedInvite.concat(action.userId).filter((value, index, self) => self.indexOf(value) === index)
            };
        case types.USER_DECLINE_INVITE:
            return {...state, acceptedInvite: state.acceptedInvite.filter((id) => id !== action.userId)};
        case types.CLEAR_ACCEPTED_INVITE:
            return {...state, acceptedInvite: []};
        case types.SET_PARTY_VIEW:
            return {...state, partyView: action.partyView};
        case types.SET_EXTEND_CALL_BLOCKED:
            return {...state, extendCallBlocked: action.flag};
        case types.SET_EXTEND_CALL_DIRECT_MODE:
            return {...state, extendCallDirectMode: true};
        case types.SET_EXTEND_CALL_BY_MINUTES:
            return {...state, extendCallByMinutes: action.minutes};
        case types.CLEAR_EXTEND_CALL_OPTIONS:
            return {...state, extendCallDirectMode: false, extendCallByMinutes: null};
        case types.NEED_SOCKET_RECONNECT:
            return {...state, needSocketReconnect: action.flag};
        case types.SET_IS_PUBLISH_PROBLEMS:
            return {...state, isPublishProblems: action.flag};
        case types.DISABLE_GREEN_SCREEN:
            return {...state, showGreenScreen: false};
        case types.SET_CONTROLLER_STORE:
            return {...state, ...action.store};
        case types.SET_DIFFERENCE_TIME:
            return {...state, differenceBetweenServerAndClientTime: action.differenceBetweenServerAndClientTime};
        case types.CLEAR_CHOICES :
            return {...state, showExtendCallPopup: false};
        case types.SET_IS_OPEN_EDIT_BREAKS_POPUP:
            return {...state, showEditBreaksPopup: action.flag};
        case types.SET_USER_SESSIONS_ACTION:
            return {...state, userSessions: action.userSessions};
        case types.SET_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        case types.SET_DISPLAY_PROFILE:
            return {...state, isDisplayProfile: action.isProfileView};
        default:
            return state
    }
}
