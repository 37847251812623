import types from '../constans';

const initialState = {
    registrationStep: 'description',
    selectedTicket: {},
    showRegistrationTicketsPopup: false,
    hideRegistrationTicketsPopup: false,
    createRegistrationOnFly: false,
    loggedInSystem: false,
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    addedBulkAttendees: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_BULK_ATTENDEES:
            return {...state, addedBulkAttendees: action.addedBulkAttendees};
        case types.SET_LOGGED_IN_SYSTEM:
            return {...state, loggedInSystem: action.loggedInSystem};
        case types.SET_REGISTRATION_STEP:
            return {...state, registrationStep: action.step};
        case types.SET_SELECTED_TICKET:
            return {...state, selectedTicket: action.ticket};
        case types.SET_CREATE_REGISTRATION_ON_FLY:
            return {...state, createRegistrationOnFly: action.flag};
        case types.SHOW_REGISTRATION_TICKETS_POPUP:
            return {...state, showRegistrationTicketsPopup: action.visible, hideRegistrationTicketsPopup: false};
        case types.HIDE_REGISTRATION_TICKETS_POPUP:
            return {...state, hideRegistrationTicketsPopup: true};
        case types.SET_LOADING:
            return {...state, isLoading: action.isLoading};
        case types.SET_ERROR_MESSAGE:
            return {...state, errorMessage: action.errorMessage};
        case types.SET_SUCCESS_MESSAGE:
            return {...state, successMessage: action.successMessage};
        default: return state
    }
}
