import styled, { keyframes } from "styled-components";
import { colors } from "../variables";

export const ButtonsWrapper = styled.div`
  padding: ${pr => pr.padding ? pr.padding : 0};

  > button {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

export const Button = styled.button`
  width: ${pr => pr.width ? pr.width : '100%'};
  padding-top: ${pr => pr.paddingTop ? pr.paddingTop : '10px'};
  padding-right: ${pr => pr.paddingRight ? pr.paddingRight : '40px'};
  padding-bottom: ${pr => pr.paddingBottom ? pr.paddingBottom : '10px'};
  padding-left: ${pr => pr.paddingLeft ? pr.paddingLeft : '40px'};
  background-color: ${pr => pr.bgColor ? colors[pr.bgColor] : colors.green};
  color: #fff;
  font-size: 16px;
  border-radius: ${pr => pr.borderRadius ? pr.borderRadius : '3px'};
  cursor: pointer;
  border: 0;
  transition: .3s;

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${pr => pr.hoverBgColor ? colors[pr.hoverBgColor] : colors.greenHover};
  }
`;

export const TransparentButton = styled.button`
  width: ${pr => pr.width ? pr.width : '100%'};
  padding-top: ${pr => pr.paddingTop ? pr.paddingTop : '10px'};
  padding-right: ${pr => pr.paddingRight ? pr.paddingRight : '40px'};
  padding-bottom: ${pr => pr.paddingBottom ? pr.paddingBottom : '10px'};
  padding-left: ${pr => pr.paddingLeft ? pr.paddingLeft : '40px'};
  background-color: transparent;
  color: #444;
  font-size: 16px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #ccc;

  &:hover {
    background-color: #E6E6E6;
  }

  &:focus {
    outline: 0;
  }
`;

export const PopupContainer = styled.div`
  position: ${({isWebinar}) => isWebinar ? 'absolute' : 'fixed'};
  top: ${({isNextMeetReadyModal}) => isNextMeetReadyModal ? '0px' : 0};
  left: 0;
  display: flex;
  width: ${({isWebinar}) => isWebinar ? 'auto' : '100%'};
  max-width: 100%;
  height: auto;
  flex-direction: row;
  justify-content: center;
  z-index: 1000;
  ${({isWebinar}) => isWebinar && `
    @media (max-width: 570px) {
      top: 58px;
      width: 300px;
      left: calc(50% - 150px) !important;
    }
  `};
`;
const slideAnimation = keyframes`
  from {
    top: -270px;
  }
  to {
    top: 0;
  }
`;
export const PopupWrapper = styled.div`
  position: relative;
  z-index: 1000;
  display: flex;
  width: auto;
  height: 50px;
  flex-direction: row;
  align-items: center;
  padding: 10px 5px;
  background-color: #3e3e3e;
  animation: 1s ${slideAnimation};

  @media (max-width: 700px) {
    height: 60px;
  }

  &:before {
    content: '';
    position: absolute;
    left: -24px;
    width: 25px;
    height: 50px;
    background-color: #3e3e3e;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 40% 100%);
    border-bottom-left-radius: 18px;

    @media (max-width: 700px) {
      height: 60px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: -24px;
    width: 25px;
    height: 50px;
    background-color: #3e3e3e;
    clip-path: polygon(0 0, 100% 0%, 60% 100%, 0 100%);
    border-bottom-right-radius: 18px;

    @media (max-width: 700px) {
      height: 60px;
    }
  }
`;

export const PopupWrapperNoAnimation = styled.div`
  position: relative;
  z-index: 1000;
  display: flex;
  width: auto;
  height: 50px;
  flex-direction: row;
  align-items: center;
  padding: 10px 5px;
  background-color: #3e3e3e;

  @media (max-width: 700px) {
    height: 60px;
  }

  &:before {
    content: '';
    position: absolute;
    left: -24px;
    width: 25px;
    height: 50px;
    background-color: #3e3e3e;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 40% 100%);
    border-bottom-left-radius: 18px;

    @media (max-width: 700px) {
      height: 60px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: -24px;
    width: 25px;
    height: 50px;
    background-color: #3e3e3e;
    clip-path: polygon(0 0, 100% 0%, 60% 100%, 0 100%);
    border-bottom-right-radius: 18px;

    @media (max-width: 700px) {
      height: 60px;
    }
  }
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 12px;
  z-index: 10000;
`;

export const PopupTimerWrapper = styled.div`
  margin-left: 20px;
  width: 15px;
  font-size: 14px;
  color: #888888;
`;

export const AcceptButton = styled.div`
  margin-left: ${pr => pr.marginLeft ? pr.marginLeft : '40px'};
  text-align: center;
  width: ${pr => pr.width ? pr.width : '85px'};
  height: 30px;
  padding: 7px 0;
  background-color: ${colors.green};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.greenHover};
  }
`;
export const DeclineButton = styled.div`
  margin-left: 5px;
  text-align: center;
  width: 85px;
  height: 30px;
  padding: 7px 0;
  background-color: #a4a4a4;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.grey};
  }
`;
