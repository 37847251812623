import Styled from "./styles";
import React from "react";

const TextMessage = ({isMine, message}) => (
    <Styled.MessageWrap isMine={isMine}>
        {message.attributes.photo ?
            <Styled.Photo isMine={isMine} photo={message.attributes.photo}/> :
            <Styled.Initials isMine={isMine}
                             initials={message.attributes.initials}
                             color={message.attributes.color}/>
        }
        <div className={'no-translate'} style={{width: '77%'}}>
            <Styled.MessageBody
                isMine={isMine}
                dangerouslySetInnerHTML={{__html: message.body}}
            />
        </div>
    </Styled.MessageWrap>
);

export default TextMessage;
