import types from '../constans';

const initialState = {
    event: {},
    eventRegistrations: [],
    accountData: {},
    organizerEvents: [],
    futureEvents: [],
    eventState: null,
    eventReminders: [],
    wrapupData: {},
    isPreviewMode: false,
    flowSteps: [],
    selfEventTags: {},
    editable: false,
    plan_status: 'pending-cancelation',
    messages: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_INITIAL_STATE_PREVIEW:
            return {...state, ...action.initialState};
        case types.SET_SELF_EVENT_TAGS:
            return {...state, selfEventTags: action.selfEventTags};
        case types.SET_EDITABLE_PREVIEW:
            return {...state, editable: action.editable}
        case types.ADD_MESSAGE:
            return {...state, messages: [...state.messages, action.message]}
        default: return state
    }
}
