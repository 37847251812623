import React, {useEffect} from 'react';
import { connect } from 'react-redux'

const FadeScreen = (props) => {
    const { surveySuccessfullyPosted, sessionConnected } = props;

    useEffect(() => {
        if (surveySuccessfullyPosted && sessionConnected) {
            document.querySelectorAll('.OT_publisher.fullscreen').forEach((video) => {
                const styles = video.getAttribute('style');
                video.setAttribute('style', styles + ' z-index: 1 !important;');
            });
            const fadeScreen = document.getElementById('fade-screen');
            if (fadeScreen) {
                fadeScreen.style.transition = 'background-color 0s';
                fadeScreen.className = 'active';
            }
        }
    }, [surveySuccessfullyPosted, sessionConnected]);

    return (<div id={'fade-screen'}> </div>)
};

export default connect(
    (store) => ({
        surveySuccessfullyPosted: store.controller.surveySuccessfullyPosted,
        sessionConnected: store.room.connected,
    }), {}
)(FadeScreen);
