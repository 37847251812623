import React from 'react'

class Timer extends React.Component{

    state =  {
        seconds: this.props.seconds
    };

    _timer = null;

    componentWillUnmount() {
        if (this._timer) {
            clearInterval(this._timer);
        }
    }

    componentDidMount() {
        this._timer = setInterval(() => {
            if (this.state.seconds > 0) {
                this.setState({ seconds: this.state.seconds -1 })
            }
            if (this.state.seconds === 0) {
                this.props.onTimeEnd();
                clearInterval(this._timer);
            }
        }, 1000);
    }

    render() {
        return (
            <span className="no-translate">{this.state.seconds}</span>
        );
    }
}

export default Timer
