import types from '../constans';

const initialState = {
  userId: null,
  directMode: false,
  inProgress: true,
  success: false,
  failed: false,
  warning: false,
  stage: 'start',
  progressMessage: '',
  showSupport: false,
  deviceInfo: {},
  stepCounter: '',
  steps: [
      {
          id: 'browser',
          state: 'pending',
          name: 'Browser',
          order: 1,
          progress: 0,
      },
      {
          id: 'equipment',
          state: 'pending',
          name: 'Equipment',
          order: 2,
          progress: 0,
      },
      {
          id: 'connection',
          state: 'pending',
          name: 'Connection',
          order: 3,
          progress: 0,
      }
  ],
  proceedUrl: '/',
  faqHeader: '',
  faq: [],
  extraFaq: [],
  equipmentModalType: null,
  showEquipmentModal: false,
  micOnly: null,
  parentUrl: null,
  isTestWebcam: false,
  permitMobile: false,
  without_webcam: false,
  showWithoutWebcamButton: false,
  videoLoaded: false,
  micSelectOptions: [],
  micSelected: '',
  webcamSelectOptions: [],
  webcamSelected: '',
  slide_webcam_flow: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER_ID :
            return {...state, userId: action.userId};
        case types.SET_TEST_DIRECT_MODE :
            return {...state, directMode: action.mode};
        case types.SET_PROCEED_URL :
            return {...state, proceedUrl: action.proceedUrl};
        case types.SET_STAGE :
            return {...state, stage: action.stage};
        case types.SET_SUCCESS :
            return {...state, success: true};
        case types.SET_FAILED :
            return {...state, failed: action.flag};
        case types.SET_WARNING :
            return {...state, warning: true};
        case types.SHOW_SUPPORT :
            return {...state, showSupport: action.flag};
        case types.SET_IN_PROGRESS :
            return {...state, inProgress: action.inProgress};
        case types.SET_PROGRESS_MESSAGE :
            return {...state, progressMessage: action.message};
        case types.SET_STEP_COUNTER :
            return {...state, stepCounter: action.counter};
        case types.SET_STEP_PROGRESS :
            return {...state, steps: state.steps.map(step => step.id === action.id ? {...step, progress: action.progress} : step) };
        case types.SET_STEP_STATE :
            return {...state, steps: state.steps.map(step => step.id === action.id ? {...step, state: action.state} : step) };
        case types.SET_EQUIPMENT_MODAL_TYPE :
            return {...state, equipmentModalType: action.modalType};
        case types.SHOW_EQUIPMENT_MODAL :
            return {...state, showEquipmentModal: action.flag};
        case types.SET_TEST_MIC_ONLY_MODE :
            return {...state, micOnly: action.micOnly};
        case types.SET_TEST_PARENT_URL :
            return {...state, parentUrl: action.data.parentUrl, isTestWebcam: action.data.isTestWebcam};
        case types.SET_TEST_PERMIT_MOBILE :
            return {...state, permitMobile: action.permitMobile};
        case types.SET_TEST_WITHOUT_WEBCAM :
            return {...state, without_webcam: true};
        case types.SHOW_WITHOUT_WEBCAM_BUTTON :
            return {...state, showWithoutWebcamButton: action.flag};
        case types.SET_FAQ_ITEMS :
            return {...state, faq: action.faq, extraFaq: action.extra, faqHeader: action.header};
        case types.SET_VIDEO_LOADED :
            return {...state, videoLoaded: action.flag};
        case types.SET_MIC_SELECTED :
            return {...state, micSelected: action.deviceId};
        case types.SET_MIC_SELECT_OPTIONS :
            return {...state, micSelectOptions: action.options};
        case types.SET_WEBCAM_SELECTED :
            return {...state, webcamSelected: action.deviceId};
        case types.SET_WEBCAM_SELECT_OPTIONS :
            return {...state, webcamSelectOptions: action.options};
        case types.SLIDE_WEBCAM_FLOW :
            return {...state, slide_webcam_flow: true};
        default: return state
    }
}
