import React from 'react';
import styled from "styled-components";
import {connect} from "react-redux";

const GrayButtonStyled = styled.button`
  display: inline-block;
  background-color: ${(props) => props.primaryButtonHex || '#CAD2D9'};
  color: ${(props) => props.primaryButtonTextHex || '#fff'};
  text-align: center;
  font-size: 16px;
  border: 1px solid ${(props) => props.primaryButtonHex || '#CAD2D9'};
  border-radius: 3px;
  opacity: 1;
  transition: background-color .3s;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.primaryButtonHoverHex || '#aeaeae'};
    border-color: ${(props) => props.primaryButtonHoverHex || '#aeaeae'};
  }

  &:focus {
    background-color: ${(props) => props.primaryButtonClickHex || '#aeaeae'};
    border-color: ${(props) => props.primaryButtonClickHex || '#aeaeae'};
    outline: none;
    box-shadow: none;
  }

  &:active {
    background-color: ${(props) => props.primaryButtonClickHex || '#aeaeae'};
    border-color: ${(props) => props.primaryButtonClickHex || '#aeaeae'};
  }

  &:disabled {
    cursor: not-allowed;
    ${(props) => props.primaryButtonDisabledHex ? `
        background-color: ${(props) => props.primaryButtonDisabledHex || '#dec0c0'};
        border-color: ${(props) => props.primaryButtonDisabledHex || '#dec0c0'};
        ` : `
            opacity: .8;
        `
    }
`;

const GrayButton = (props) => (
    <GrayButtonStyled {...{...props}} />
);

const mapStateToProps = (state) => ({
    // primaryButtonHex: state.controller.currentEvent.primaryButtonHex,
    // primaryButtonClickHex: state.controller.currentEvent.primaryButtonClickHex,
    // primaryButtonDisabledHex: state.controller.currentEvent.primaryButtonDisabledHex,
    // primaryButtonHoverHex: state.controller.currentEvent.primaryButtonHoverHex,
    // primaryButtonTextHex: state.controller.currentEvent.primaryButtonTextHex,
});

export default connect(mapStateToProps)(GrayButton);
