import React from "react";
import styled from "styled-components";

const GreenButtonStyled = styled.button`
  display: inline-block;
  background-color: #2fad64;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-family: Inter;
  line-height: 1.6;
  border-radius: 3px;
  transition: background 200ms ease 0s;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: #2a9b5a;
  }

  &:focus {
    background-color: #2a9b5a;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background-color: #2a9b5a;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #2fad64;
    border-color: #2fad64;
    opacity: .8;
  }
`;

const IndependentGreenButton = (props) => (
    <GreenButtonStyled {...{...props}} />
);

export default IndependentGreenButton;
