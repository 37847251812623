import types from "../constans";

export const setInitialStatePreviewAction = (initialState) => ({type: types.SET_INITIAL_STATE_PREVIEW, initialState});

export const getStepByAlias = (alias) => (dispatch, getState) => {
    const {preview: {flowSteps}} = getState();
    const currentFlow = flowSteps.find(flow => flow.steps.find(step => step.alias === alias));

    if (currentFlow && currentFlow.id) {
        return currentFlow.steps.find(step => step.alias === alias);
    }
    return {};
};

export const getFlowByAlias = (alias) => (dispatch, getState) => {
    const {preview: {flowSteps}} = getState();
    return flowSteps.find(flow => flow.alias === alias);
};

export const isEventFlowById = (id) => (dispatch, getState) => {
    const {preview: {flowSteps}} = getState();
    const flow = flowSteps.find(flow => flow.id === id);

    return flow && flow.alias && flow.alias === 'event';
};

export const isEventFlowsStepById = (currentFlowId, stepId, flows = []) => (dispatch, getState) => {
    if (!isEventFlowById(currentFlowId)) return false;
    const {preview: {flowSteps}} = getState();
    const flow = flowSteps.find(flow => flow.id === currentFlowId);
    const step = flow.steps.find(step => step.id === stepId);
    return step && step.alias && flows.includes(step.alias);
};

export const setSelfEventTagsAction = (selfEventTags={}) => (dispatch) => {
    dispatch({
        type: types.SET_SELF_EVENT_TAGS,
        selfEventTags
    })

};

export const addMessageAction = (message) => ({type: types.ADD_MESSAGE, message});

export const setEditablePreviewAction = (editable) => ({type: types.SET_EDITABLE_PREVIEW, editable});
