import types from '../constans';

const initialState = {
    firstTimeSearch: true,
    fishbowlButtonSelected: 0,
    fishbowlPartyView: 'default'
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_FISHBOWL_BUTTON_SELECTED :
            return {...state, fishbowlButtonSelected: action.button, fishbowlPartyView: action.partyView};
        case types.SET_FALSE_FIRST_TIME_SEARCH_STATE :
            return {...state, firstTimeSearch: false};
        case types.CLEAR_FISHBOWL_OPTIONS :
            return {...state, fishbowlButtonSelected: 0, fishbowlPartyView: 'default', firstTimeSearch: true};
        case types.SET_FISHBOWL_STORE:
            return {...state, ...action.store};
        default: return state
    }
}