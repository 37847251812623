import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";


const GreenButtonStyled = styled.button`
    display: inline-block;
    background-color: ${(props) => props.primaryButtonHex || '#30AD64'};
    color: ${(props) => props.primaryButtonTextHex || '#fff'};
    text-align: center;
    font-size: 16px;
    border: 1px solid ${(props) => props.primaryButtonHex || '#30AD64'};
    border-radius: 3px;
    opacity: 1;
    transition: background-color .3s;
 
    &:hover {
      cursor: pointer;
      background-color: ${(props) => props.primaryButtonHoverHex || '#239E58'};
      border-color: ${(props) => props.primaryButtonHoverHex || '#239E58'};
    }
    &:focus {
      background-color: ${(props) => props.primaryButtonClickHex || '#239E58'};
      border-color: ${(props) => props.primaryButtonClickHex || '#239E58'};
      outline: none;
      box-shadow: none;
    }
    &:active {
      background-color: ${(props) => props.primaryButtonClickHex || '#1b6d3b'};
      border-color: ${(props) => props.primaryButtonClickHex || '#1b6d3b'};
    }
    &:disabled {
      cursor: not-allowed;      
    }
`;

const GreenButton = (props) => (
    <GreenButtonStyled {...{...props}} />
);

const mapStateToProps = (state) => ({
    primaryButtonHex: state.controller.currentEvent.primaryButtonHex,
    primaryButtonClickHex: state.controller.currentEvent.primaryButtonClickHex,
    primaryButtonDisabledHex: state.controller.currentEvent.primaryButtonDisabledHex,
    primaryButtonHoverHex: state.controller.currentEvent.primaryButtonHoverHex,
    primaryButtonTextHex: state.controller.currentEvent.primaryButtonTextHex,
});

export default connect(mapStateToProps)(GreenButton);
