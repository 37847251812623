export default {
    START_EARLY: 'startEarly',
    VIDEO_DISCONNECTED_I_USER: 'videoDisconnectedIUser',
    VIDEO_DISCONNECTED_I_USER_15: 'videoDisconnectedIUser15',
    VIDEO_DISCONNECTED_O_USER: 'videoDisconnectedOUser',
    VIDEO_DISCONNECTED_O_USER_15: 'videoDisconnectedOUser15',
    VIDEO_NO_SHOW: 'videoNoShow',
    BREAK: 'break',
    MET_EVERYONE: 'metEveryone',
    WRAPUP: 'wrapup',
    VIDEO_DESTROYED: 'videoDestroyed',
    VIDEO_PARTNER_LEFT: 'videoPartnerLeft',
    VIDEO_PARTNER_MOVED_ON: 'videoPartnerMovedOn',
}
