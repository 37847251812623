import types from '../constans';
import { setIdentifiedLogrocketAction } from "./actions";

const initialState = {
    initializedLogrocket: false,
    identifiedLogrocket: false,
    micOnlyMode: false,
    currentUser: {
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        emailKey: '',
        title: '',
        company: '',
        profilePhoto: '',
        profileColorHex: '',
        bio: '',
        location: '',
        age: '',
        region: '',
        content: {},
        notes: [],
        eventTags: {},
        organizerMode: false,
        inRoom: false,
        viewedIntroToMeetaway: false,
        ticket: '',
        invoice: null,
        isAdmin: false,
        counterOfCreatedEvents: 0,
        defaultAccount: '',
        coOrganizerEventsIds: [],
        accountUserRole: []
    },

    otherUser: {
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        company: '',
        profilePhoto: '',
        profileColorHex: '',
        bio: '',
        location: '',
        age: '',
        region: '',
        content: {},
        notes: [],
        eventTags: {},
        organizerMode: false,
        inRoom: false,
        viewedIntroToMeetaway: false,
    },

    otherUserB: {
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        company: '',
        profilePhoto: '',
        profileColorHex: '',
        bio: '',
        location: '',
        age: '',
        region: '',
        content: {},
        notes: [],
        eventTags: {},
        organizerMode: false,
        inRoom: false,
        viewedIntroToMeetaway: false,
    },

    invitedUser: {
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        company: '',
        profileColorHex: '',
        profilePhoto: '',
        fromHolding: false
    },

    accountCustomization: {
        loading: true,
        logo: '',
        domain: '',
        default_background_image: '',
        default_background_image_has_dark_shade: '',
        primary_button_hex: '',
        primary_button_click_hex: '',
        primary_button_disabled_hex: '',
        primary_button_hover_hex: '',
        primary_button_text_hex: '',
        profile_elements: ''
    },

    nextPartner: null,
    previousUsers: [],
    nextUsers: [],

    partnersBioProgress: {},
    partnersBioProgressesFetched: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER_DEFAULT_ACCOUNT :
            return {...state, currentUser: {...state.currentUser, defaultAccount: action.accountId}};
        case types.SET_MIC_ONLY_MODE :
            return {...state, micOnlyMode: action.isMicOnly};
        case types.SET_CURRENT_USER :
            return {...state, currentUser: action.user};
        case types.SET_OTHER_USER :
            return {...state, otherUser: action.user};
        case types.SET_OTHER_USER_B :
            return {...state, otherUserB: action.user};
        case types.SET_INVITED_USER :
            return {...state, invitedUser: action.user};
        case types.SET_NEXT_PARTNER :
            return {...state, nextPartner: action.user};
        case types.UNSET_NEXT_PARTNER :
            return {...state, nextPartner: null};
        case types.SET_PREVIOUS_USERS :
            return {...state, previousUsers: action.previousUsers};
        case types.SET_NEXT_USERS :
            return {...state, nextUsers: action.nextUsers};
        case types.SET_ACCOUNT_CUSTOMIZATION :
            return {...state, accountCustomization: action.accountCustomization};
        case types.SAVE_NOTES : {
            const {notes} = state.currentUser;
            if (!notes) {
                return {...state, currentUser: {...state.currentUser, notes: [action.newNotes]}};
            }
            const index = notes.findIndex((item) => item.id === action.newNotes.id);
            if (index !== -1) {
                notes[index] = action.newNotes;
            } else {
                notes.push(action.newNotes);
            }
            return {...state, currentUser: {...state.currentUser, notes}};
        }
        case types.SET_USERS_STORE:
            return {...state, ...action.store};

        case types.RESET_PARTNER_BIO_PROGRESSES_FETCHED :
            return {...state, partnersBioProgressesFetched: false};
        case types.SET_PARTNER_BIO_PROGRESSES :
            return {...state, partnersBioProgress: action.partnersBioProgress, partnersBioProgressesFetched: true};
        case types.SET_PARTNER_COMPLETE_PRIOR_CALL :
            return {...state,
                partnersBioProgress: {
                    ...state.partnersBioProgress,
                    [action.userId]: {...state.partnersBioProgress[action.userId] || {}, priorCallCompleted: true}
                }
            };
        case types.SET_PARTNER_COMPLETE_SURVEY :
            return {...state,
                partnersBioProgress: {
                    ...state.partnersBioProgress,
                    [action.userId]: {...state.partnersBioProgress[action.userId] || {}, surveyCompleted: true}
                }
            };
        case types.SET_PARTNER_COMPLETE_JOINING :
            return {...state,
                partnersBioProgress: {
                    ...state.partnersBioProgress,
                    [action.userId]: {...state.partnersBioProgress[action.userId] || {}, joined: action.joined}
                }
            };
        case types.SET_PARTNER_COMPLETE_MATCHES :
            return {...state,
                partnersBioProgress: {
                    ...state.partnersBioProgress,
                    [action.userId]: {...state.partnersBioProgress[action.userId] || {}, matchesCompleted: true}
                }
            };
        case types.CLEAR_INVOICE :
            return {...state, currentUser: {...state.currentUser, invoice: null}};

        case types.SET_INITIALIZED_LOGROCKET :
            return {...state, initializedLogrocket: true};

       case types.SET_IDENTIFIED_LOGROCKET :
            return {...state, identifiedLogrocket: true};

        default:
            return state
    }
}
