import styled from "styled-components";

const MessageContainer = styled.div`
  display: flex;
  padding: 0.4em 1em;
  margin: 0.6em 0;
  border: 2px solid #e4e7e9;
  border-radius: 4px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MediaInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.5em;
  min-width: 0;

  & p {
    margin: 0;
    font-size: 12px;
  }
`;

const Filename = styled.p`
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Size = styled.p`
  font-weight: 400;
`;


export default {
    MessageContainer,
    IconContainer,
    MediaInfo,
    Filename,
    Size
}