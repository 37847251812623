import types from '../constans';

const initialState = {
    isLoaded: false,
    virtualBackground: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_SYSTEM_SETTINGS:
            return {...state, isLoaded: true, ...action.settings};
        default: return state
    }
}