import styled from "styled-components";

const green = '#00b050';
const blue = '#00b0f0';

const MessageWrap = styled.div`
  margin: 4px 0 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: ${pr => pr.isMine ? 'row-reverse' : 'row'};
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;

const Photo = styled(Avatar)`
  background: url('${pr => pr.photo}') no-repeat center center;
  background-size: cover;
`;

const Initials = styled(Avatar)`
  border: 2px solid ${pr => pr.color === 'transparent' ? '#C0C0C0' : pr.color};
  background-color: ${pr => pr.color};
  color: #fff;

  &:before {
    content: '${pr => pr.isMine ? 'ME' : (pr.initials || 'IN')}';
  }
`;

const MessageBody = styled.div`
  position: relative;
  padding: 8px;
  background-color: ${pr => pr.isMine ? green : blue};
  border-radius: 8px;
  word-break: break-word;
  white-space: pre-line;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    ${pr => pr.isMine ? "left: 100%" : 'right: 100%'};
    border-color: ${pr => pr.isMine ?
            `transparent transparent transparent ${green}` :
            `transparent ${blue} transparent transparent`
    };
    border-width: ${pr => pr.isMine ? '5px 0 5px 6px' : '5px 6px 5px 0'};
  }
`;

export default {
    MessageBody,
    Photo,
    Initials,
    MessageWrap
}