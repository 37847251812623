import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { connect } from "react-redux";
import DOMPurify from "dompurify";

import CloseXButton from "../Common/CloseXButton";
import { getDataOptions } from "../../utils/helpers";
import { colors } from "../variables";
import socketModule from "../../utils/socketModule";

const slide = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: absolute;
  width: 565px;
  max-width: 95%;
  height: auto;
  max-height: 600px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgb(221, 221, 221) 1px 1px 4px 2px;
  top: 30%;
  right: 50%;
  transform: translate(50%, -128px);
  z-index: 99999999;
  opacity: 1;
  animation: .6s linear 0s ${slide};
  ${pr => pr.hideWrapupNotesPopup ? `animation: .6s linear 0s hideModal` : ''};
  @media (max-width: 450px) {
    width: 95%;
  }
  @keyframes hideModal {
    from { opacity: 1;}
    to { opacity: 0;}
  }
  @media (max-height: 500px) {
    top: 70%;
  }
`;

const Main = styled.div`
  background-color: #eeeeee;
  color: #595959;
  padding: 15px 30px 30px 30px;
  font-size: 16px;
  max-height: 300px;
  overflow: auto;
`;

const Header = styled.div`
  padding: 30px 30px 15px 30px;
`;

const Title = styled.div`
  color: #444444;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;

  @media (max-width: 450px) {
    font-size: 18px;
  }
`;
const SubTitle = styled.div`
  color: #444444;
  font-size: 18px;
  line-height: 1.2;

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const ButtonBlock = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  padding: 10px 40px 10px 40px;
  background-color: ${colors.green};
  color: #fff;
  font-size: 16px;
  border-radius: 3px;
  cursor: pointer;
  border: 0;
  transition: .3s;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: ${colors.greenHover};
  }  
  &:active {
    background-color: ${colors.greenActive};
  }

  @media (max-width: 450px) {
    font-size: 14px;
    padding: 10px 15px;
  }
`;

const LiveMessage = ({eventId}) => {
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (eventId) {
            socketModule.socket.emit('liveMessages.connect_to_channel', {eventId});
        }

        socketModule.socket.on('liveMessages.receive_message', ({message}) => {
            setMessage(message);
        });

        return ()=> {
            socketModule.socket.off('liveMessages.receive_message');
        };
    }, [eventId]);

    const closeHandler = () => {
        setMessage(null);
    };

    if (!message) return null;

    return (
        <Container>
            <Header>
                <CloseXButton
                    style={{top: 0, right: 10}}
                    onClick={closeHandler} />
                <Title>
                    Message from the Organizer
                </Title>
                <SubTitle>
                    Live update
                </SubTitle>
            </Header>
            <Main>
                <div
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message || '').replace(/\n/g, '<br/>') }}
                />
            </Main>
            <ButtonBlock>
                <Button
                    data-trackable={
                        getDataOptions({
                            view: "LiveMessage Popup",
                            type: "button",
                            label: "Close"
                        })
                    }
                    onClick={closeHandler}>
                    Close
                </Button>
            </ButtonBlock>
        </Container>
    );
};

const mapStateToProps = (store) => ({
    eventId: store.controller.currentEvent.eventId,
});

export default connect(mapStateToProps)(LiveMessage);
