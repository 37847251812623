import React from "react";
import styled from 'styled-components';
import { connect } from 'react-redux'

const StyledMessage = styled.span`
  margin-top: 20px;
`;

const ErrorInfoBlock = (props) => {

    return (
        <p>
            {/*{props.reasons.common && (*/}
            {/*    <StyledMessage>*/}
            {/*        Ooops, let's try that again...*/}
            {/*    </StyledMessage>*/}
            {/*)}*/}

            {(props.reasons.accessDenied && !props.showSupport) && (
                <StyledMessage>
                    Ooops, there's a problem with access to your devices. Please try refreshing the page, share access to your microphone and camera.
                </StyledMessage>
            )}

            {props.reasons.noConnection && (
                <StyledMessage>
                    Please refresh your page. Your internet connection has dropped.
                </StyledMessage>
            )}

            {props.reasons.networkError && (
                <StyledMessage>
                    Your internet connection dropped; we're trying to reconnect you.
                </StyledMessage>
            )}

            {props.reasons.networkWarning && (
                <StyledMessage>
                    Your internet connection is unstable.
                </StyledMessage>
            )}
            {props.reasons.reconnectingWarning &&
                !props.showNextPartnerBioPopup && !props.showRightFlyInPopup &&
                !props.showFishbowlPopup && !props.showSurveyPopup && (
                <StyledMessage>
                    Reconnecting...
                </StyledMessage>
            )}
        </p>
    )
};

export default connect(
    (store) => ({
        showNextPartnerBioPopup: store.controller.showNextPartnerBioPopup,
        showSupport: store.test.showSupport,
        showRightFlyInPopup: store.controller.showRightFlyInPopup,
        showFishbowlPopup: store.controller.showFishbowlPopup,
        showSurveyPopup: store.controller.showSurveyPopup,
    })
)(ErrorInfoBlock)
