import React, {useState} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMobileAlt, faLink} from "@fortawesome/free-solid-svg-icons";
import Vimeo from '@u-wave/react-vimeo'
import {getDataOptions} from "../../utils/helpers";

const SupportContainer = styled.div`
   width: 60%;
   font-family: Inter;
`;

const Header = styled.div`
  padding: 30px 0 5px;
  font-size: 22px;
`;

const Hr = styled.div`
  margin-bottom: 10px;
  border-bottom: 1px solid silver;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 5px;
`;

const HelpContainer = styled.div`
  width: 100%;
  height: 0;
  opacity: 0;
  transition: height, opacity .3s ease-out;
  overflow: hidden;
  z-index: -1;
  &.visible {
    height: auto;
    opacity: 1;
    z-index: 10;
  }
  .video-embed, .vimeo-embed {
    display: block;
    overflow: hidden;
  }
`;

const IconVsText = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;
const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #00B1F1;
  font-size: 18px;
  margin-right: 10px;
`;
const TextWrapper = styled.div`
    width: 100%;
    text-align: left;
    padding-top: 5px;
`;

const Text = styled.span`
  display: inline;
  font-size: 16px;
  color: white;
  font-weight: 400;
  text-align: left;
  
  &.description {
  padding-left: 3px;
    color: #ccc;
  }
  a {
      color: rgb(0, 177, 241);
      text-decoration: none;
  }
`;

const Button = styled.div`
  display: flex;
  width: 160px;
  min-width: 160px;
  height: 33px;
  padding: 0;
  background: none ${pr => pr.green ? '#2fad64' : '#eee'};
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  border-radius: 3px;
  transition: background 200ms ease 0s;
  color: ${pr => pr.green ? '#fff' : '#00b1f1'};
  border: ${pr => pr.green ? '1px none #ccc' : '1px solid #ccc'};
  &:hover {
    cursor: pointer;
    background: none ${pr => pr.green ? '#239e58' : '#f0f0f0'};
    border: ${pr => pr.green ? '1px none #ccc' : '1px solid #00b1f1'};
  }
`;

const OpenExtraFaqWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

const OpenExtraFaq = styled(Button)`
  width: 70%;
  color: #ddd;
  background: none transparent;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 700;
  height: auto;
  padding: 12px;
  &:hover {
    cursor: pointer;
    color: #fff;
    background: none rgba(255, 255, 255, 0.1);
    border-color: #ccc;;
  }
`;
const VimeoPlayer = styled(Vimeo)`
  width: 95%;
  background-color: #eee;
  margin: 10px auto;
`;

const FaqItem = ({item}) => {
    const { text, icon, sources } = item;
    const type = sources[0] ? sources[0].type : 'simple';
    const path = sources[0] ? sources[0].source : '';
    const label = sources[0] ? sources[0].label : '';
    const [visible, setVisible] = useState(false);
    const getButtonText = (type) => {
        if (type === 'video') return !visible ? 'Show video' : 'Hide video';
        else return !visible ? 'Show image' : 'Hide image';
    };
    const renderText = () => {
        const regexp = /(.*)\((.*)\)/gi;
        const parts = regexp.exec(text);
        if (!parts || !(parts[1] && parts[2])) return (
          <TextWrapper>
              <Text>{text}</Text>
              {type === 'link' && <><br/><Text><a href={path} target="_blank">{label}</a></Text></>}
          </TextWrapper>
        );

        return (
          <TextWrapper>
              <Text>{parts[1]}</Text>
              {type === 'link' && <><br/><Text><a href={path} target="_blank">{label}</a></Text></>}
              <Text className={'description'}>({parts[2]})</Text>
          </TextWrapper>
        )
    };

    const getIcon = () => {
        switch (icon) {
            case 'mobile':
                return faMobileAlt;
            case 'link':
                return faLink;
            default:
                return icon;
        }
    };

    return (
        <ItemWrapper>
            <ItemGrid>
                <IconVsText>
                    <Icon>
                        {!!icon && <FontAwesomeIcon icon={getIcon()} /> }
                    </Icon>
                    { renderText() }
                </IconVsText>
                {
                    (type === 'image' || type === 'video') &&
                    <Button green={visible}
                            data-trackable={
                                getDataOptions({
                                    view: 'Support',
                                    type: 'button',
                                    label: getButtonText(type)
                                })
                            }
                            onClick={ () => {setVisible(!visible)} }>
                        {getButtonText(type)}
                    </Button>
                }
            </ItemGrid>
            {
                (type === 'image' || type === 'video') &&
                <HelpContainer className={visible ? 'visible' : ''}>
                    {type === 'image' ?
                        <img src={path} width="95%" alt={text}/> :
                        <VimeoPlayer responsive={true} width={'95%'} video={path}  />
                    }
                </HelpContainer>
            }
        </ItemWrapper>
    );
};

const Support = (props) => {
    const { faq, extraFaq } =  props;
    const [extraFaqShowed, showExtraFaq] = useState(false);

    return (
        <SupportContainer>
            <Header>
                Simple fixes that actually work
            </Header>
            <Hr />
            { faq.map(item => <FaqItem key={item.id} item={item} />) }
            {(!extraFaqShowed && !!extraFaq.length) &&
                <OpenExtraFaqWrapper>
                    <OpenExtraFaq
                        data-trackable={
                            getDataOptions({
                                view: 'Support',
                                type: 'button',
                                label: "That didn't work. Show Additional Common Fixes."
                            })
                        }
                        onClick={() => {
                        showExtraFaq(true)
                    }}>
                        That didn't work. Show Additional Common Fixes.
                    </OpenExtraFaq>
                </OpenExtraFaqWrapper>
            }
            { extraFaqShowed && extraFaq.map(item => <FaqItem key={item.id} item={item} />) }
        </SupportContainer>
    );
};

const mapStateToProps = (state) => ({
    inProgress: state.test.inProgress,
    progressMessage: state.test.progressMessage,
    steps: state.test.steps,
    faq: state.test.faq,
    extraFaq: state.test.extraFaq
});
const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Support)
