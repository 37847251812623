import React from 'react';
import styled from 'styled-components';

const StyledMessage = styled.span`
  margin-top: 20px;
`;

const TwoStringsMessageWrapper = styled.div`
  margin-top: 20px;
`;

const InfoBlock = (props) => {
    return (
        <>
            {props.reasons.conLow && (
                <TwoStringsMessageWrapper>
                    <div>A poor internet connection is degrading the video quality.</div>
                    <div>So we've prioritized your audio so you can continue to hear each other.</div>
                </TwoStringsMessageWrapper>
            )}

            {props.reasons.conLowOther && (
                <TwoStringsMessageWrapper>
                    <div>A poor internet connection is degrading the video quality.</div>
                    <div>So we've prioritized your audio so you can continue to hear each other.</div>
                </TwoStringsMessageWrapper>
            )}

            {props.reasons.browserReloaded && (
                <StyledMessage>
                    Hang tight, you'll get an opportunity to rematch shortly if your partner doesn't reconnect.
                </StyledMessage>
            )}
        </>
    )
};

export default InfoBlock;