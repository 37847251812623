import React, { useEffect, useState } from "react";
import * as axios from "axios";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import moment from "moment";
import momentTimezone from "moment-timezone";
import _ from "lodash";
import { setup } from "axios-cache-adapter";

import config from "./config";
import store from "../store";
import types from "../store/constans";
import { createUpdateEventReminderAction } from "../store/organizer/actions";
import { setIdentifiedLogrocketAction, setInitializedLogrocketAction } from "../store/users/actions";
import { updateDifferenceBetweenServerAndClientTime } from "../store/controller/actions";
import { getSystemToken, logout } from "./AuthManager";
import mobileLogo from "../assets/img/meetawaybluewhite.png";


const getLogRocketId = () => {
    switch (true) {
        case document.location.pathname.includes('/mux-room/'):
            return config.LOG_ROCKET_MUX_ROOM_APP_ID;
        case document.location.pathname.includes('/room/'):
            return config.LOG_ROCKET_ROOM_APP_ID;
        case document.location.pathname.includes('/zoom-room/'):
            return config.LOG_ROCKET_ZOOM_ROOM_APP_ID;
       case document.location.pathname.includes('/new-video-recorder/'):
            return config.LOG_ROCKET_RECORDER_APP_ID;
        case document.location.pathname.includes('/m/'):
            return config.LOG_ROCKET_ORGANIZER_APP_ID;
        case document.location.pathname.includes('/event/'):
            return config.LOG_ROCKET_REGISTRATION_APP_ID;
        case document.location.pathname.includes('/directory/'):
            return config.LOG_ROCKET_DIRECTORY_APP_ID;
        case document.location.pathname.includes('/settings'):
            return config.LOG_ROCKET_SETTINGS_APP_ID;
        case document.location.pathname.includes('/check-in/'):
        case document.location.pathname.includes('/live-event/'):
        case document.location.pathname.includes('/wrapup/'):
            return config.LOG_ROCKET_CHECKIN_APP_ID;
        default:
            return null;
    }
};

export const setupLogRocket = () => {
    const appId = getLogRocketId();
    if (!appId) {
        console.log('no need to setup LogRocket: ', document.location.pathname);
        return;
    }

    // Init LogRocket:
    const {users: {initializedLogrocket}} = store.getState();
    if (!initializedLogrocket) {
        console.warn(' LogRocket.init: ', appId);
        LogRocket.init(appId, {
            network: {
                requestSanitizer: request => {
                    const url = request && request.url && request.url.toLowerCase ? request.url.toLowerCase() : null;

                    if (url && (url.includes('virtualbackground') || url.includes('lib') || url.includes('/static/') || !url.includes('meetaway.com'))) {
                        // ignore the request response pair
                        return null;
                    }

                    // otherwise log the request normally
                    return request;
                },
            },
        });

        store.dispatch(setInitializedLogrocketAction());
    }

    setTimeout(()=> {
        LogRocket.getSessionURL(sessionURL => {
            Sentry.getCurrentScope().setTag('sessionURL', sessionURL);
        });
    }, 1000);
};

export const identifyLogRocket = (user = {}, eventId = '', isSaveLogRocketSessionUrl = false) => {
    const appId = getLogRocketId();
    if (!appId) {
        console.log('no need to identify LogRocket: ', document.location.pathname);
        return;
    }

    const isCustomer = !!(user && user.email);

    if (isCustomer && eventId) {
        setTimeout(()=> {
            store.dispatch(updateDifferenceBetweenServerAndClientTime({
                eventId,
                userId: user.userId
            }));
        }, 5000);
    }

    const oldTempId = localStorage.getItem(`temp-auth-${eventId}`);
    const tempId = oldTempId ? oldTempId : JSON.stringify(Date.now());

    if (!isCustomer && !oldTempId) {
        localStorage.setItem(`temp-auth-${eventId}`, tempId);
    }

    const id = isCustomer ? user.userId : `${eventId}_${tempId}`;
    const name = isCustomer ? `${user.firstName} ${user.lastName}` : `${eventId}_${tempId}`;
    const userData = {
        name,
        eventId,
        email: isCustomer ? user.email : '',
    };

    setTimeout(()=>{
        console.warn('LogRocket.identify: ', { id, userData });
        const {users: {identifiedLogrocket}} = store.getState();
        if (isCustomer && !identifiedLogrocket) {
            store.dispatch(setIdentifiedLogrocketAction());
            LogRocket.identify(id, userData);

            if (isSaveLogRocketSessionUrl) {
                LogRocket.getSessionURL(async (sessionURL) => {
                    const {
                        users: { currentUser },
                        controller: { currentEvent }
                    } = store.getState();

                    if (currentUser && currentUser.userId && currentEvent && currentEvent.eventId) {
                        await getInstanceAxios().post(`${config.NODE_API_URL}/event-registrations/save-logrocket-session-url`, {
                            sessionURL,
                            eventId: currentEvent.eventId,
                            userId: currentUser.userId
                        });
                    }
                });
            }
        }
    }, 2000);
};

export const isMobileScreenSize = () => window && (window.innerWidth <= 737 || window.innerHeight <= 500);
export const isMobileLandscape = () => window && isMobileScreenSize() && window.innerHeight < window.innerWidth;

export const otHash = btoa(config.CHECK_IN_SECRET);

export const invoiceStatuses = {
    pending: 'pending',
    success: 'success',
    failed: 'failed',
    canceled: 'canceled',
};

export const ticketStatuses = {
    pending: 'pending',
    active: 'active',
    expired: 'expired',
    canceled: 'canceled',
};

export const logoOnLight = isMobileScreenSize() ? mobileLogo : '//s3.amazonaws.com/appforest_uf/d29/f1598716491899x503608735835290750/meetawayblueblack.png';
export const logoOnDark = isMobileScreenSize() ? mobileLogo : '//s3.amazonaws.com/appforest_uf/d29/f1592185784043x945853159357826000/meetawaybluewhite.png';

export const isMobileHorizontal = window.innerWidth < 668;
export const isMobile = () => window.innerWidth < 450 || window.innerHeight < 450;
export const isMobileOrganizerTabs = () => window.innerWidth < 502;
export const isTablet = window.innerWidth < 912;

export const isIOS = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const initCrisp = () => {
    console.warn('initCrisp');
    window.$crisp = [];
    window.$crisp.push(["safe", true]);
    window.CRISP_WEBSITE_ID = "8fa29cea-2754-44f6-a542-ad29f38c39d7";
    const d = document;
    const s = d.createElement("script");
    (() => {
        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
    })();
};

export const sendCrispDataWithoutEventFields = () => {
    const {users: {currentUser}} = store.getState();

    if (window.$crisp && currentUser && currentUser.userId) {
        (async () => {
            const {data} = await getInstanceAxios().post(`${config.NODE_API_URL}/event-registrations/user-registrations-dates`, {
                userId: currentUser.userId,
                eventId: null,
            }, {crossDomain: true});

            window.$crisp.push(["safe", true]);
            window.$crisp.push(["set", "user:email", data && data.user_email ? data.user_email : '-']);
            window.$crisp.push(["set", "session:data", [
                [
                    ["user_id", data && data.user_id ? data.user_id : '-'],
                    ["cur_event_id", data && data.cur_event_id ? data.cur_event_id : '-'],
                    ["cur_event_name", data && data.cur_event_name ? data.cur_event_name : '-'],
                    ["cur_event_account_id", data && data.cur_event_account_id ? data.cur_event_account_id : '-'],
                    ["plan", data && data.plan ? data.plan : '-'],
                    ["account_owner", data && data.account_owner ? data.account_owner : '-'],
                    ["cur_role", data && data.cur_role ? data.cur_role : '-'],
                    ["events_organized", data && data.events_organized ? data.events_organized : '-'],
                    ["events_attended", data && data.events_attended ? data.events_attended : '-'],
                ]
            ]]);
            window.$crisp.push(["set", "user:nickname", data && data.nickname ? data.nickname.trim() : '-']);
            window.$crisp.push(["set", "user:company", data && data.company ? data.company : '-']);
            window.$crisp.push(["set", "user:avatar", data && data.avatar ? data.avatar : '-']);
        })();
    }
};

export const sendCrispData = () => {
    const {controller: {currentEvent}, users: {currentUser}} = store.getState();
    if (window.$crisp && currentUser && currentUser.userId && currentEvent && currentEvent.eventId) {
        (async () => {
            const {data} = await getInstanceAxios().post(`${config.NODE_API_URL}/event-registrations/user-registrations-dates`, {
                userId: currentUser.userId,
                eventId: currentEvent.eventId,
            }, {crossDomain: true});

            window.$crisp.push(["safe", true]);
            window.$crisp.push(["set", "user:email", data && data.user_email ? data.user_email : '-']);
            window.$crisp.push(["set", "session:data", [
                [
                    ["user_id", data && data.user_id ? data.user_id : '-'],
                    ["cur_event_id", data && data.cur_event_id ? data.cur_event_id : '-'],
                    ["cur_event_name", data && data.cur_event_name ? data.cur_event_name : '-'],
                    ["cur_event_account_id", data && data.cur_event_account_id ? data.cur_event_account_id : '-'],
                    ["plan", data && data.plan ? data.plan : '-'],
                    ["account_owner", data && data.account_owner ? data.account_owner : '-'],
                    ["cur_role", data && data.cur_role ? data.cur_role : '-'],
                    ["events_organized", data && data.events_organized ? data.events_organized : '-'],
                    ["events_attended", data && data.events_attended ? data.events_attended : '-'],
                ]
            ]]);
            window.$crisp.push(["set", "user:nickname", data && data.nickname ? data.nickname.trim() : '-']);
            window.$crisp.push(["set", "user:company", data && data.company ? data.company : '-']);
            window.$crisp.push(["set", "user:avatar", data && data.avatar ? data.avatar : '-']);
        })();
    }
};

export const isEmailValid = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const isPasswordValid = (password) => {
    if (!password || !password.length) return false;
    return password.length >= 6;
};

export const getUnique = (arr, comp) => {
    return arr
        .map(e => e[comp])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);
};

export const sanitizeInfo = (obj) => {
    Object.keys(obj).forEach(key => {
        if (obj[key] === 'null' || !obj[key]) obj[key] = '';
    });
    return obj;
};

export const getUserName = (user, isThreeWayCall) => {
    const firstNameLength = user.firstName ? user.firstName.length : 0;
    const lastNameLength = user.lastName ? user.lastName.length : 0;
    const totalLength =  firstNameLength + lastNameLength;
    const maxCharacters = isThreeWayCall ? 7 : 23;

    return <span className="no-translate name">{`${user.firstName} ${totalLength <= maxCharacters ? user.lastName : ''}`}</span>;
};

export const getUserLocation = (user) => {
    if (!user.location || user.location === 'null') return '';

    const [city, state] = user.location.split(',');
    if (city && state) return `${city.trim()}, ${state.trim()}`;
    return city ? city : state ? state : '';
};

export const getUserInitials = (user, rawText) => {
    const firstLetterFirstName = user.firstName ? user.firstName.slice(0, 1) : '';
    const firstLetterLastName = user.lastName ? user.lastName.slice(0, 1) : '';

    if (rawText) return (firstLetterFirstName + firstLetterLastName).toUpperCase();

    return <span className="no-translate">{(firstLetterFirstName + firstLetterLastName).toUpperCase()}</span>;
};

export const getUserCompanyInfo = (user) => {
    const title = _.get(user, 'title', '');
    const company = _.get(user, 'company', '');

    return <span className="no-translate">{title} {(title && company) ? '@' : ''} {company}</span>;
};

export const getUserCompanyInfoForAttendeeAndSponsor = user => {
    const title = user && user.title ? `${user.title} ` : '';
    const company = user && user.company ? `@ ${user.company}` : '';

    return <span className="no-translate">{title}{company}</span>;
}

const checkColor = (color) => {
    if (!color) return false;
    return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);
};

export const getProfilePhoto = (user) => {
    const profilePhoto = _.get(user, 'profilePhoto', null);
    return profilePhoto !== 'null' ? profilePhoto : null;
};

export const getProfileColor = (user) => {
    const {profileColorHex} = user;
    return checkColor(profileColorHex) ? profileColorHex : '#' + Math.floor(Math.random() * 16777215).toString(16);
};

export const getProfileColorHex = (color) => {
    return checkColor(color) ? color : '#' + Math.floor(Math.random() * 16777215).toString(16);
};

export const prepareUserData = (data) => {
    const {
        user, first_name, last_name, email, email_key, title, company, profile_color_hex, profile_photo,
        bio, location, age, region, content, notes, event_tags, organizerMode, inRoom, tickets, preferred_language,
        viewed_intro_to_meetaway, invoice, plan, account_owner, events_organized, events_attended, is_admin,
        user_timezone, counterOfCreatedEvents, gender, default_account, coOrganizerEventsIds,
        accountUserRole, twitter_link, linkedin_link
    } = data;

    const eventContent = {};
    if (content && content.length) {
        content.forEach(item => {
            eventContent[item.label] = item.content;
        });
    }

    return {
        tickets,
        accounts: accountUserRole && accountUserRole.length ? accountUserRole.map(({account}) => account) : [],
        defaultAccount: default_account,
        gender,
        userTimezone: user_timezone,
        preferredLanguage: preferred_language,
        userId: user,
        firstName: first_name,
        lastName: last_name,
        email,
        emailKey: email_key,
        title,
        company,
        profileColorHex: getProfileColorHex(profile_color_hex),
        profilePhoto: profile_photo,
        bio,
        location,
        age: Number(age),
        region,
        content: eventContent,
        notes: notes || [],
        eventTags: event_tags,
        organizerMode,
        inRoom,
        viewedIntroToMeetaway: viewed_intro_to_meetaway,
        ticket: tickets && tickets.length && tickets.find(ticket => ticket.status === 'active')
            ? tickets.find(ticket => ticket.status === 'active') : null,
        invoice,
        plan: plan,
        accountOwner: account_owner,
        eventsOrganized: events_organized,
        eventsAttended: events_attended,
        isAdmin: is_admin,
        counterOfCreatedEvents,
        coOrganizerEventsIds,
        accountUserRole,
        twitterLink: twitter_link,
        linkedinLink: linkedin_link,
    };
};

export const prepareEventData = (data, accountData) => {
    const {
        organizer_id,
        name,
        start_date_and_time,
        number_of_sessions,
        min_per_session,
        extend_call_enabled,
        algorithm,
        mic_only,
        session_format,
        session_format_rematch,
        event,
        internal_end_date_and_time,
        background_image,
        default_virtual_background,
        individual_matching,
        choose_matches_early,
        event_tags,
        has_kickoff_video,
        show_welcome_to_meetaway,
        objectives,
        kickoff_video_label,
        has_chat_support,
        organizer,
        privacy_setting,
        show_1min_notice,
        published_status,
        registration_required,
        sponsor_logos,
        permit_mobile,
        enable_translation,
        account_id,
        organizer_name,
        organizer_description,
        exit_page_primary_cta,
        exit_page_button_cta,
        exit_page_link_url,
        description,
        event_tickets,
        exit_page_primary_btn_color,
        exit_page_primary_btn_text_color,
        start_timezone,
        event_duration,
        wrapup_survey,
        kickoff_video_url,
        kickoff_video_thumbnail,
        wrapup_video_thumbnail,
        label_objectives,
        event_duration_text,
        profile_elements,
        other_profile_element,
        profile_elements_other,
        kickoff_video_player,
        kickoff_video_id,
        wrapup_video_url,
        wrapup_video_player,
        wrapup_video_id,
        has_wrapup_video,
        sendgrid_signature,
        email_registration_confirmation,
        email_ical_invite,
        email_three_day_matches,
        email_two_day_matches,
        email_one_day_matches,
        email_one_day_reminder,
        email_event_canceled,
        email_attendee_canceled_ticket,
        email_organizer_canceled_ticket,
        email_two_hour_reminder,
        email_fifteen_minutes_reminder,
        email_post_event_emails,
        email_prompt_to_complete_survey,
        defaultWrapupId,
        customWrapupSurveyQuestions,
        team_note,
        pwd_not_required,
        profile_elements_other1,
        profile_elements_other2,
        profile_elements_other3,
        sendgrid_from_name,
        coOrganizers,
        is_inperson,
        paid_out,
        eventbrite_event_id,
        webinar_enabled,
        webinar_duration,
        hosts,
        speakers,
        viewersOnStage,
        speakersOffStage,
        is_started_webinar,
        webinar_is_over,
        sendgrid_reply_to_email,
        networking_rounds,
        account_subscription_plan,
        account_subscription_plan_id,
        hide_inactive_bulk_uploaded_attendees,
        start_on_demand,
        record_video_key,
        profile_elements_link1,
        kickoff_conversion_status,
        wrapup_conversion_status,
        is_recurring_event,
        repeating,
        recurringEvents,
        bandwidth_profile_mode,
        objectives_type,
        wrapup_shows_only_first_question,
        custom_survey_url,
        webinar_sticky_message,
        faq
    } = data;

    return {
        coOrganizers, faq,
        hosts: hosts && hosts.length ? hosts.map(({bubble_user, webinar_role})=> {
            return { ...prepareUserData(bubble_user), webinarRole: webinar_role };
        }) : [],
        speakers: speakers && speakers.length ? speakers.map(({bubble_user, webinar_role})=> {
            return { ...prepareUserData(bubble_user), webinarRole: webinar_role };
        }) : [],
        viewersOnStage: viewersOnStage && viewersOnStage.length ? viewersOnStage.map(({bubble_user, webinar_role})=> {
            return { ...prepareUserData(bubble_user), webinarRole: webinar_role };
        }) : [],
        speakersOffStage: speakersOffStage && speakersOffStage.length ? speakersOffStage.map(({bubble_user, webinar_role})=> {
            return { ...prepareUserData(bubble_user), webinarRole: webinar_role };
        }) : [],
        isStartedWebinar: is_started_webinar,
        webinarIsOver: webinar_is_over,
        account: accountData,
        hasWhitelabel: accountData ? accountData.has_whitelabel : false,// is premium account
        organizerId: organizer_id,
        profileElementsOther1: profile_elements_other1,
        profileElementsOther2: profile_elements_other2,
        profileElementsOther3: profile_elements_other3,
        profileElementsLink1: profile_elements_link1,
        teamNote: team_note,
        accountExitPagePrimaryCta: accountData && accountData.accountCustomization ? accountData && accountData.accountCustomization.exit_page_primary_cta : '',
        accountExitPageLinkUrl: accountData && accountData.accountCustomization ? accountData.accountCustomization.exit_page_link_url : '',
        accountExitPageButtonCta: accountData && accountData.accountCustomization ? accountData.accountCustomization.exit_page_button_cta : '',
        customWrapupSurveyQuestions,
        defaultWrapupId,
        checkinPage: accountData && accountData.accountCustomization ? accountData.accountCustomization.checkin_page : '',
        sendgridDomain: config.SENDGRID_DOMAIN,
        emailPostEventEmails: email_post_event_emails,
        emailPromptToCompleteSurvey: email_prompt_to_complete_survey,
        emailFifteenMinutesReminder: email_fifteen_minutes_reminder,
        emailTwoHourReminder: email_two_hour_reminder,
        emailOneDayReminder: email_one_day_reminder,
        emailEventCanceled: email_event_canceled,
        emailAttendeeCanceledTicket: email_attendee_canceled_ticket,
        emailOrganizerCanceledTicket: email_organizer_canceled_ticket,
        emailOneDayMatches: email_one_day_matches,
        emailTwoDayMatches: email_two_day_matches,
        emailThreeDayMatches: email_three_day_matches,
        emailIcalInvite: email_ical_invite,
        emailRegistrationConfirmation: email_registration_confirmation,
        sendgridSignature: sendgrid_signature,
        sendgridReplyToEmail: sendgrid_reply_to_email,
        sendgridFromName: sendgrid_from_name,
        kickoffVideoId: kickoff_video_id,
        kickoffVideoPlayer: kickoff_video_player,
        wrapupVideoUrl: wrapup_video_url,
        wrapupVideoPlayer: wrapup_video_player,
        wrapupVideoId: wrapup_video_id,
        hasWrapupVideo: has_wrapup_video,
        otherProfileElementText: profile_elements_other,
        otherProfileElement: other_profile_element,
        profileElements: profile_elements ? profile_elements : '',
        eventDurationText: event_duration_text,
        labelObjectives: label_objectives,
        // profileElements: accountData ? accountData.profile_elements : '',
        breakImage: accountData && accountData.accountCustomization ? accountData.accountCustomization.break_image : '',
        breakMusicPlaylists: accountData && accountData.accountCustomization ? accountData.accountCustomization.break_music_playlists : null,
        defaultBackgroundImage: accountData && accountData.accountCustomization ? accountData.accountCustomization.default_background_image : '',
        intersessionSurvey: accountData && accountData.accountCustomization ? accountData.accountCustomization.intersession_survey : '',
        accountName: accountData ? accountData.name : '',
        primaryButtonHex: accountData && accountData.accountCustomization ? accountData && accountData.accountCustomization.primary_button_hex : '',
        primaryButtonClickHex: accountData && accountData.accountCustomization ? accountData && accountData.accountCustomization.primary_button_click_hex : '',
        primaryButtonDisabledHex: accountData && accountData.accountCustomization ? accountData && accountData.accountCustomization.primary_button_disabled_hex : '',
        primaryButtonHoverHex: accountData && accountData.accountCustomization ? accountData && accountData.accountCustomization.primary_button_hover_hex : '',
        primaryButtonTextHex: accountData && accountData.accountCustomization ? accountData && accountData.accountCustomization.primary_button_text_hex : '',
        logoOnLight: accountData && accountData.accountCustomization ?  accountData.accountCustomization.logo_on_light : logoOnLight,
        logoOnDark: accountData && accountData.accountCustomization ? accountData.accountCustomization.logo_on_dark : logoOnDark,
        accountDomain: (accountData && accountData.domain) ? `https://${accountData.domain}` : config.BUBBLE_MAIN_URL,
        accountSubscriptionPlan: (accountData && accountData.accountSubscriptionPlan) ? accountData.accountSubscriptionPlan.plan_id : '',
        apiSecretToken: accountData ? accountData.api_secret_token || '' : '',
        eventbriteApiKey: accountData ? accountData.eventbrite_api_key || '' : '',
        eventbriteClientSecret: accountData ? accountData.eventbrite_client_secret || '' : '',
        eventbriteApiKeyCommonValue: accountData ? accountData.eventbrite_api_key_common_value || '' : '',
        eventbriteClientSecretCommonValue: accountData ? accountData.eventbrite_client_secret_common_value || '' : '',
        apiSecretTokenCommonValue: accountData ? accountData.api_secret_token_common_value || '' : '',
        eventbritePrivateToken: accountData ? accountData.eventbrite_private_token || '' : '',
        eventbritePrivateTokenCommonValue: accountData ? accountData.eventbrite_private_token_common_value || '' : '',
        eventbriteEventId : eventbrite_event_id || '',
        accountId: account_id || '',
        backgroundImage: background_image,
        defaultVirtualBackground: default_virtual_background,
        eventId: event,
        name,
        wrapupSurvey: wrapup_survey,
        startDateAndTime: start_date_and_time,
        internalEndDateAndTime: internal_end_date_and_time,
        kickoffVideoUrl: kickoff_video_url,
        numberOfSessions: number_of_sessions,
        minPerSession: min_per_session,
        micOnly: mic_only,
        permitMobile: permit_mobile,
        sessionFormat: session_format || types.SESSION_FORMAT_1TON,
        hideInactiveBulkUploadedAttendees: !!hide_inactive_bulk_uploaded_attendees,
        sessionFormatRematch: session_format_rematch || types.SESSION_FORMAT_1TON,
        extendCallEnabled: extend_call_enabled,
        algorithm: algorithm,
        objectives: objectives,
        individualMatching: individual_matching,
        chooseMatchesEarly: choose_matches_early,
        eventTags: event_tags ? JSON.parse(event_tags) : {},
        hasKickoffVideo: has_kickoff_video,
        kickoffVideoLabel: kickoff_video_label,
        kickoffVideoThumbnail: kickoff_video_thumbnail,
        wrapupVideoThumbnail: wrapup_video_thumbnail,
        showWelcomeToMeetaway: show_welcome_to_meetaway,
        hasChatSupport: has_chat_support,
        organizer: organizer ? prepareUserData(organizer) : {},
        organizerName: organizer_name,
        organizerDescription: organizer_description,
        description,
        timezone: start_timezone,
        eventTickets: event_tickets,
        privacySetting: privacy_setting,
        show1MinNotice: show_1min_notice,
        publishedStatus: published_status,
        registrationRequired: registration_required,
        sponsorLogos: sponsor_logos ? sponsor_logos.split(',').map(item => item.trim()) : [],
        enableTranslation: enable_translation,
        exitPagePrimaryCta: exit_page_primary_cta,
        exitPageButtonCta: exit_page_button_cta,
        exitPageLinkUrl: exit_page_link_url,
        exitPagePrimaryBtnColor: exit_page_primary_btn_color,
        exitPagePrimaryBtnTextColor: exit_page_primary_btn_text_color,
        duration: event_duration,
        pwdNotRequired: pwd_not_required,
        isInperson: is_inperson,
        paidOut: paid_out,
        webinarEnabled: webinar_enabled,
        webinarDuration: webinar_duration,
        networkingRounds: networking_rounds,
        eventSubscriptionPlan: account_subscription_plan,
        eventSubscriptionPlanId: account_subscription_plan_id,
        objectivesType: objectives_type,
        startOnDemand: start_on_demand,
        recordVideoKey: record_video_key,
        kickoffConversionStatus: kickoff_conversion_status,
        wrapupConversionStatus: wrapup_conversion_status,
        bandwidthProfileMode: bandwidth_profile_mode,
        wrapupShowsOnlyFirstQuestion: wrapup_shows_only_first_question,
        isRecurringEvent: is_recurring_event,
        repeating,
        recurringEvents,
        customSurveyUrl: custom_survey_url,
        webinarStickyMessage: webinar_sticky_message,
    };
};

export const prepareEventRegistrationData = (data) => {
    const {
        event_id, user_id, event_registration, event_tags, meet_tags, organizer_mode, is_sponsor, user_score,
        available_for_matching_session_num, user_user_preferences, checked_in, status,
        current_step, show_objectives, checkin_timestamps, is_disabled_matching_after_wrapup,
        eventbrite_order_id, webinar_role, break_session_numbers
    } = data;

    return {
        eventId: event_id,
        userId: user_id,
        status: status,
        currentStep: current_step,
        eventRegistration: event_registration,
        eventTags: event_tags ? JSON.parse(event_tags) : {},
        meetTags: meet_tags ? JSON.parse(meet_tags) : {},
        organizerMode: organizer_mode,
        isSponsor: is_sponsor,
        showObjectives: show_objectives,
        userScore: user_score,
        availableForMatchingSessionNum: available_for_matching_session_num,
        userUserPreferences: user_user_preferences || [],
        timestamps: checkin_timestamps || {},
        checkedIn: checked_in,
        isDisabledMatchingAfterWrapup: is_disabled_matching_after_wrapup,
        eventbriteOrderId: eventbrite_order_id,
        webinarRole: webinar_role,
        breakSessionNumbers: break_session_numbers ? JSON.parse(break_session_numbers) : []
    };
};

export const is2WayCall = (numberUsers, otherUser, otherUserB) => {
    if (parseInt(numberUsers) === 2) return true;
    return !!(parseInt(numberUsers) === 3 && ((otherUser.inRoom && !otherUserB.inRoom) || (!otherUser.inRoom && otherUserB.inRoom)));
};
export const is3WayCall = (otherUser, otherUserB) => {
    return !!(otherUser.userId && otherUser.inRoom && otherUserB.userId && otherUserB.inRoom);
};

export const roundNumber = (inputValue, decimal = 2) => {
    const numberInputValue = Number.parseFloat(inputValue);

    if (!Number.isFinite(numberInputValue) || Number.isNaN(numberInputValue)) {
        return 0;
    }

    return Number.parseFloat(numberInputValue.toFixed(decimal));
};

export const isCheckInFlow = (excluded = '') => {
    if (!excluded || !excluded.length) {
        return window.location.pathname.includes('check-in');
    }
    return window.location.pathname.includes('check-in') && !window.location.pathname.includes(excluded);
};

export const isDirectoryFlow = (isPreviewMode = false) => {
    if (isPreviewMode) {
        return window.location.pathname.includes('/3/2');
    }
    return window.location.pathname.indexOf('directory') !== -1;
};

export const getFormattedEventPaidPlan = (eventTickets, isSoldOut) => {
    if (!eventTickets || !eventTickets.length) return '';
    if (isSoldOut) return 'Sold Out';
    if (eventTickets.length === eventTickets.filter(ticket => !ticket.price || parseInt(ticket.price) === 0).length) return 'Free';
    if (eventTickets.length === 1) return eventTickets[0].is_free ? 'Free' : `$${eventTickets[0].price}`;
    const sorted = eventTickets.filter(ticket => !ticket.is_hidden).sort((a, b) => parseFloat(a.price) > parseFloat(b.price) ? 1 : -1);
    const minPrice = sorted[0].price;
    const maxPrice = sorted.reverse()[0].price;
    const minPriceParseFloat = parseFloat(minPrice);
    const maxPriceParseFloat = parseFloat(maxPrice);

    const countCentNumbersMinPrice = (minPriceParseFloat + '').split('.')[1] ? (minPriceParseFloat + '').split('.')[1].length : 0;
    const countCentNumbersMaxPrice = (maxPriceParseFloat + '').split('.')[1] ? (maxPriceParseFloat + '').split('.')[1].length : 0;

    return `$${minPriceParseFloat+(countCentNumbersMinPrice === 1 ? '0' : '')}-${maxPriceParseFloat+(countCentNumbersMaxPrice === 1 ? '0' : '')}`
};

export const stopStream = (stream) => {
    if (stream == null) return;
    const tracks = stream.getTracks();
    if (tracks instanceof Array) {
        tracks.forEach((track) => {
            track.stop();
        });
    } else {
        console.warn('stream.getTracks() return NOT Array');
    }
};

export const checkOsAndBrowser = () => {
    const detectRTCData = store.getState().checkin.detectRtcData;
    const event = store.getState().controller.currentEvent;
    if (!event.permitMobile && detectRTCData && detectRTCData.isMobileDevice) return {
        passed: false,
        errorMessage: `Please use a laptop or desktop with Chrome, Firefox, Safari 13+, Edge 79+ to join because this event doesn't permit joining from a phone/tablet.`,
        subErrorMessage: 'We know this can be inconvenient but the people you meet prefer you being on a laptop or desktop. Thanks for your understanding.',
        eventId: event.eventId
    };
    if (detectRTCData && ((detectRTCData.browser.isSafari && detectRTCData.browser.version < 11) || detectRTCData.browser.isEdge || detectRTCData.browser.isIE)) return {
        passed: false,
        errorMessage: `Please use Chrome, Firefox, Safari 13+, Edge 79+ because our video system doesn't support ${detectRTCData.browser.name} ${detectRTCData.browser.version}`,
        subErrorMessage: `We know this is inconvenient for you but the people you meet appreciate you using a browser that supports the newest video conferencing technologies. Thanks for your understanding.`,
        eventId: event.eventId
    };

    return {
        passed: true,
        errorMessage: '',
        subErrorMessage: '',
        eventId: event.eventId
    };
};

export const smoothScrollToBottom = (nodeId, duration = 2000, callback) => {
    let timer = null;
    const setTimer = (data) => {
        timer = data;
    };

    const settings = {
        duration: duration,
        outQuint: (x, t, b, c, d) => c * ((t = t / d - 1) * t * t * t * t + 1) + b
    };

    let node = document.getElementById(nodeId);

    let startTime = Date.now();
    const nodeHeight = node.scrollHeight - node.offsetHeight;
    const offset = 0;

    if (timer) {
        clearInterval(timer);
    }

    const step = () => {
        let yScroll;
        const elapsed = Date.now() - startTime;

        if (elapsed > settings.duration) {
            clearTimeout(timer);
        }

        const percentage = elapsed / settings.duration;

        if (percentage > 1) {
            clearTimeout(timer);

            if (callback) {
                callback();
            }
        } else {
            yScroll = settings.outQuint(0, elapsed, offset, nodeHeight, settings.duration);
            node.scrollTo(0, yScroll);
            setTimer({timer: setTimeout(step, 10)});
        }
    };
    setTimer({timer: setTimeout(step, 10)});
};



const instanceAxios = axios.create({
    crossDomain: true,
    headers: {
        'Content-Type': 'application/json',
    }
});

const instanceAxiosWithCache = setup({
    crossDomain: true,
    headers: {
        'Content-Type': 'application/json',
    },
    cache: {
        exclude: {
            query: false,
            methods: ['delete'],
        },
        maxAge: 60 * 1000, // 1 min
    }
});

export const getInstanceAxios = (enableCache = false) => {
    const instance = enableCache ? instanceAxiosWithCache : instanceAxios;

    instance.interceptors.request.use(
        config => {
            config.headers['Authorization'] = `Bearer ${getSystemToken('run_as_jwt_token') || getSystemToken('jwt_token') || getSystemToken('limited_token')}`;
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );
    instance.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response && error.response.status === 403 && error.response.data && error.response.data.type === 'User not found') {
            console.warn('Problem with the validity of the JWT');
            logout();
        }
        if (error.response && error.response.status === 403 && error.response.data && error.response.data.type === 'Access denied') {
            console.warn('You have not enough rights');
            const currentLocation = document.location.href;
            if ((currentLocation.includes('/check-in/') || currentLocation.includes('/live-event/')) && !currentLocation.includes('/welcome/')) {
                const {controller: {currentEvent: {eventId}}} = store.getState();
                window.document.location = `/check-in/welcome/${eventId}`;
            } else {
                window.document.location = '/403';
            }
        }

        return Promise.reject(error);
    });

    return instance;
};

export const getFormattedRichEditorMessage = (editorContent) => {
    if (!editorContent) return '';
    return draftToHtml(convertToRaw(editorContent.getCurrentContent()))
};

export const bytesToSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getEventDurations = ({addHours = true, start = 15, end = 120, step = 5}) => {
    const durations = [];

    for (let minutes = start; minutes <= end; minutes += step) {
        let label = '';
        const hours = minutes / 60 ^ 0;
        let mins = minutes % 60;
        if (hours) {
            if (mins === 0) {
                label = hours === 1 ? `${hours} hour` : `${hours} hours`;
            } else {
                if (mins < 10) mins = '0' + mins;
                label = `${hours} hr ${mins} mins`
            }
        } else {
            label = `${mins} mins`
        }
        durations.push({
            value: minutes,
            label: label,
            disabled: !addHours && minutes > 60
        });
    }

    [3, 4, 6, 8].forEach(hours =>
        durations.push({
            value: hours * 60,
            label: `${hours} hours`,
            disabled: !addHours
        })
    );

    return durations;
};

export const convertHex = (hex, opacity) => {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
};

export const formatEventAdminPanel = (events) => events.map((event) => ({
    label: `${moment(event.start_date_and_time).format('YYYY-MM-DD')} - ${event.name}`,
    value: event.event
}));

export const getDataOptions = (data) => JSON.stringify({
    ...{
        view: '',
        type: 'button',
        label: '',
        destination: ''
    },
    ...data
});

export const initStripe = (callback = () => {
}) => {
    const stripeScript = document.createElement('script');
    stripeScript.addEventListener("load", () => {
        if (config.STRIPE_PUBLIC_KEY) {
            callback(true);
        }
    }, false);
    stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
    document.head.appendChild(stripeScript);
};

export const initMuxPlayer = (callback = () => {
}) => {
    const script = document.createElement('script');
    script.addEventListener("load", () => {
            callback(true);
    }, false);
    script.setAttribute('src', "https://cdn.jsdelivr.net/npm/@mux/mux-player");
    document.head.appendChild(script);
};

export const initAC = () => {
    const script = document.createElement('script');
    script.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");';
    document.head.appendChild(script);
};

export const getSyncDateNow = () => {
    const differenceBetweenServerAndClientTime = store.getState().controller.differenceBetweenServerAndClientTime;
    return Date.now() + differenceBetweenServerAndClientTime;
};

export const getUserPreferredLanguage = () => navigator.language || navigator.userLanguage || null;

export const emptyFunction = () => {
};

export const addNoIndexNoFollowNoArchiveTagsToMeta = () => {
    const meta = document.createElement('meta');
    meta.name = "robots";
    meta.content = "noindex,nofollow,noarchive";
    document.getElementsByTagName('head')[0].appendChild(meta);
}

export const getEmailStatus = (emailType) => {
    const emailStatusMap = [
        {
            id: 1,
            eventFieldName: 'emailRegistrationConfirmation',
            emailType: 'registrationConfirmation'
        },
        {
            id: 2,
            eventFieldName: 'emailIcalInvite',
            emailType: 'iCalInvite'
        },
        {
            id: 3,
            eventFieldName: 'emailThreeDayMatches',
            emailType: 'threeDayMatches'
        },
        {
            id: 4,
            eventFieldName: 'emailTwoDayMatches',
            emailType: 'twoDayMatches'
        },
        {
            id: 5,
            eventFieldName: 'emailOneDayMatches',
            emailType: 'oneDayMatches'
        },
        {
            id: 6,
            eventFieldName: 'emailOneDayReminder',
            emailType: 'oneDayReminder'
        },
        {
            id: 7,
            eventFieldName: 'emailTwoHourReminder',
            emailType: 'twoHourReminder'
        },
        {
            id: 8,
            eventFieldName: 'emailFifteenMinutesReminder',
            emailType: 'fifteenMinutesReminder'
        },
        {
            id: 9,
            eventFieldName: 'emailPostEventEmails',
            emailType: 'postEventConnections'
        },
    ];
    return emailStatusMap.find(item => item.emailType === emailType)
        ? emailStatusMap.find(item => item.emailType === emailType).eventFieldName
        : false;
}

export const getUniqueId = (separator = 'x') => {
    // Bubble: 1592616800703x619539133015392100
    const firstMin = Math.ceil(1111111111111);
    const firstMax = Math.floor(9999999999999);
    const secondMin = Math.ceil(111111111111111111);
    const secondMax = Math.floor(999999999999999999);

    return Math.floor(Math.random() * (firstMax - firstMin + 1) + firstMin)
        + separator +
        Math.floor(Math.random() * (secondMax - secondMin + 1) + secondMin);
};

export const isExistTagsWithoutAB = (tags) => {
    if (!tags) return null;

    let tagsObject = tags || {};
    if (typeof tagsObject === 'string') {
        tagsObject = JSON.parse(tags || '{}');
    }

    return !!(tagsObject && Object.keys(tagsObject).filter((group) => (
        tagsObject[group] && tagsObject[group].weight !== 200)
    ).length);
};
export const isExistABTags = (tags) => {
    if (!tags) return false;

    let tagsObject = tags || {};
    if (typeof tagsObject === 'string') {
        tagsObject = JSON.parse(tags || '{}');
    }
    if (tagsObject && tagsObject["Group*"] && tagsObject["Group*"].tags && tagsObject["Group*"].tags.length) {
        return tagsObject["Group*"].weight === 200;
    }
    return false;
};
export const isExistRequiredTags = (tags) => {
    let tagsObject = tags || {};
    if (typeof tagsObject === 'string') {
        tagsObject = JSON.parse(tags || '{}');
    }

    let required = false;
    Object.keys(tagsObject).forEach((group) => {
        if (tagsObject[group].required) required = true;
    });

    return required;
};
export const getABTagsByRegistration = (tags) => {
    if (!tags) return null;

    let tagsObject = tags || {};
    if (typeof tagsObject === 'string') {
        tagsObject = JSON.parse(tags || '{}');
    }

    if (tagsObject && tagsObject["Group*"] && tagsObject["Group*"].length) {
        return {"Group*": tagsObject["Group*"]};
    }
    return null;
};

export const getTagsByRegistrationWithoutAB = (tags) => {
    if (!tags) return null;

    let tagsObject = tags || {};
    if (typeof tagsObject === 'string') {
        tagsObject = JSON.parse(tags || '{}');
    }

    const groups = Object.keys(tagsObject);
    const filteredTags = {};
    groups.forEach((group) => {
        if (group !== "Group*") {
            filteredTags[group] = tagsObject[group];
        }
    });

    return filteredTags;
};

export const getTagsWithoutAB = (tags) => {
    if (!tags) return null;

    const groups = Object.keys(tags);
    const filteredTags = {};
    groups.forEach((group) => {
        if (tags[group] && tags[group].weight < 200 && !tags[group].required) {
            filteredTags[group] = tags[group];
            filteredTags[group].tags = tags[group].tags.map((tag) => ({
                ...tag, categoryFor: tags[group].categoryFor
            }));
        }
    });

    return filteredTags;
};

export const getABTags = (tags) => {
    if (!tags) return [];

    let filteredTags = [];

    if (tags["Group*"] && tags["Group*"].weight === 200) {
        filteredTags = tags["Group*"].tags;
    }

    return filteredTags;
};
export const getRequiredTags = (tags) => {
    if (!tags) return null;

    const groups = Object.keys(tags);
    return groups.reduce((acc, item) => {
        const currentTag = tags[item];
        const categoryFor = currentTag.categoryFor;
        if (!currentTag || !currentTag.required || !currentTag.tags || !currentTag.tags.length) {
            return acc;
        }

        return {
            ...acc,
            [item]: {
                ...currentTag,
                tags: currentTag.tags.map((tag) => {
                    return {
                        ...tag,
                        categoryFor
                    };
                })
            }
        };
    }, {});
};

export const truncateString = (str, num) => {
    if (!str) return '';
    if (str.length <= num) return str;

    return str.replace(/"/g, "'").slice(0, num) + '...';
};

export const getFormattedDate = (date) => {
    return momentTimezone(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY HH:mm A');
};

export const getDate = (event, recipient) => {
    const {user_timezone} = recipient;
    const {start_date_and_time, start_timezone} = event;
    const timezone = user_timezone || start_timezone || 'UTC';

    const startDateAndTimeTimezone = moment.tz(moment(start_date_and_time), timezone);
    return startDateAndTimeTimezone.format('MMMM D, YYYY');
}

export const getTime = (event, recipient) => {
    const {user_timezone} = recipient;
    const {start_date_and_time, start_timezone} = event;
    const timezone = user_timezone || start_timezone || 'UTC';

    const startDateAndTimeTimezone = moment.tz(moment(start_date_and_time), timezone);
    return `${startDateAndTimeTimezone.format('h:mma')} ${startDateAndTimeTimezone.format('z')}`;
}

export const getTitle = (template, currentEvent, numWantToMeetSinceVisitedDirectory, numWantToMeet, currentUser) => {
    const sendgrid_domain = config.SENDGRID_DOMAIN;
    const checkin_page = currentEvent.checkinPage || 'check-in/welcome';

    const regexps = [
        {
            id: 111,
            reg: /%NumWantToMeetSinceVisitedDirectory%/g,
            replaceValue: numWantToMeetSinceVisitedDirectory ? numWantToMeetSinceVisitedDirectory : '#'
        },
        {
            id: 112,
            reg: /%NumWantToMeet%/g,
            replaceValue: numWantToMeet ? numWantToMeet : '#'
        },
        {
            id: 1,
            reg: /%EventName%/g,
            replaceValue: currentEvent.name
        },
        {
            id: 2,
            reg: /%EventDate%/g,
            replaceValue: currentEvent.startDateAndTime//need format
        },
        {
            id: 3,
            reg: /%EventTime%/g,
            replaceValue: currentEvent.startDateAndTime//need format
        },
        {
            id: 4,
            reg: /%EventDuration%/g,
            replaceValue: currentEvent.eventDurationText
        },
        {
            id: 5,
            reg: /%FirstName%/g,
            replaceValue: currentUser.firstName
        },
        {
            id: 6,
            reg: /%CheckinLink%/g,
            replaceValue: `<a href=https://${sendgrid_domain}/${checkin_page}/${currentEvent.eventId}?email=${encodeURIComponent(currentUser.email)}&source=${template.email_type}>check-in link</a>`
        },
        {
            id: 7,
            reg: /%TeamSignature%/g,
            replaceValue: currentEvent.sendgridSignature
        },
    ];
    const {subject} = template;

    let updatedSubject = subject;
    regexps.forEach(reg => {
        if (reg.id !== 2 && reg.id !== 3) {
            updatedSubject = updatedSubject.replace(reg.reg, reg.replaceValue)
        } else {
            if (reg.id === 2) {
                updatedSubject = updatedSubject.replace(reg.reg, getDate(currentEvent, currentUser))
            }
            if (reg.id === 3) {
                updatedSubject = updatedSubject.replace(reg.reg, getTime(currentEvent, currentUser))
            }
        }
    });
    const maxSymbols = 32;
    return updatedSubject.length < maxSymbols + 1 ? updatedSubject : updatedSubject.slice(0, maxSymbols + 1).concat('...');
}

export const updateHtmlByRegexps = (data, currentEvent, recipient, currentEmailType, isOnlyUrlDecode = false) => {
    const sendgrid_domain = config.SENDGRID_DOMAIN;
    const checkin_page = currentEvent.checkinPage || 'check-in/welcome';

    const baseRegexps = [
        {
            id: 1,
            reg: /%EventName%/g,
            replaceValue: currentEvent.name
        },
        {
            id: 2,
            reg: /%EventDate%/g,
            replaceValue: currentEvent.startDateAndTime//need format
        },
        {
            id: 3,
            reg: /%EventTime%/g,
            replaceValue: currentEvent.startDateAndTime//need format
        },
        {
            id: 4,
            reg: /%EventDuration%/g,
            replaceValue: currentEvent.eventDurationText
        },
        {
            id: 5,
            reg: /%FirstName%/g,
            replaceValue: recipient.firstName
        },
        {
            id: 6,
            reg: /%CheckinLink%/g,
            replaceValue: `<a href=https://${sendgrid_domain}/${checkin_page}/${currentEvent.eventId}?email=${encodeURIComponent(recipient.email)}&source=${currentEmailType}>check-in link</a>`
        },
        {
            id: 7,
            reg: /%TeamSignature%/g,
            replaceValue: currentEvent.sendgridSignature
        },
        {
            id: 8,
            reg: /%ApolloDomain%/g,
            replaceValue: sendgrid_domain
        },
        {
            id: 9,
            reg: /%EventAccountID%/g,
            replaceValue: currentEvent.accountId
        },
        {
            id: 10,
            reg: /%EventID%/g,
            replaceValue: currentEvent.eventId
        },
        {
            id: 11,
            reg: /%Email%/g,
            replaceValue: encodeURIComponent(recipient.email)
        },
    ];

    const onlyUrlsRegexps = [
        {
            id: 6,
            reg: /%CheckinLink%/g,
            replaceValue: `<a href=https://${config.SENDGRID_DOMAIN}/${checkin_page}/${currentEvent.eventId}?email=${encodeURIComponent(recipient.email)}&source=${currentEmailType}>check-in link</a>`
        },
        {
            id: 8,
            reg: /%ApolloDomain%/g,
            replaceValue: sendgrid_domain
        },
        {
            id: 9,
            reg: /%EventAccountID%/g,
            replaceValue: currentEvent.accountId
        },
        {
            id: 10,
            reg: /%EventID%/g,
            replaceValue: currentEvent.eventId
        },
        {
            id: 11,
            reg: /%Email%/g,
            replaceValue: encodeURIComponent(recipient.email)
        },
    ];

    const regexps = !isOnlyUrlDecode
        ? baseRegexps
        : onlyUrlsRegexps

    let updatedData = data;
    regexps.forEach(reg => {
        if (reg.id !== 2 && reg.id !== 3) {
            updatedData = updatedData.replace(reg.reg, reg.replaceValue)
        } else {
            if (reg.id === 2) {
                updatedData = updatedData.replace(reg.reg, getDate(currentEvent, recipient))
            }
            if (reg.id === 3) {
                updatedData = updatedData.replace(reg.reg, getTime(currentEvent, recipient))
            }
        }
    });
    return updatedData;
}

export const scrollToElementById = (id, timeout = 0, scroll = false) => {
    setTimeout(() => {
        const currentElement = document.getElementById(id);

        if (currentElement) {
            if (scroll) {
                currentElement.scroll({behavior: 'smooth', top: 0});
            } else {
                currentElement.scrollIntoView({behavior: 'smooth'});
            }
        }
    }, timeout);
}

export const getIsUserAccountRole = (account_user_roles, userRole, userId) => {
    return account_user_roles && account_user_roles.length
        && account_user_roles.find(({role, user_id}) => role.name === userRole && user_id === userId);
}

export const getAges = () => {
    const beginAge = 18;
    const endAge = 110;
    const ages = [{label: 'Not specified', value: null}];

    for (let i = beginAge; i <= endAge; i++) {
        ages.push({label: `${i}yr old`, value: i});
    }
    return ages;
}

export const getFtFlagFromAccountSubscriptionPlan = (currentEvent, key) => {
    let eventSubscriptionPlan = currentEvent.eventSubscriptionPlan;
    let accountSubscriptionPlan = currentEvent.account;

    if (!currentEvent || !currentEvent.account || !currentEvent.account.accountSubscriptionPlan) {
        return true;
    }
    if (currentEvent.account.plan_status !== 'active' && !currentEvent.account.plan_status.includes('pending')) {
        return true;
    }

    let currentPlan;
    if (eventSubscriptionPlan) {
        const eventTierLevel = +eventSubscriptionPlan.tier_level;
        let accountTierLevel = +accountSubscriptionPlan.accountSubscriptionPlan.tier_level;

        if (eventTierLevel >= accountTierLevel) {
            currentPlan = eventSubscriptionPlan;
        } else {
            currentPlan = accountSubscriptionPlan.accountSubscriptionPlan;
        }

        return !currentPlan[key]
    }

    return !currentEvent.account.accountSubscriptionPlan[key];
};

export const getIsFieldInProfileElements = (currentEvent, fieldName) => {
    if (!currentEvent || !currentEvent.profileElements || !fieldName) {
        return false;
    }
    return currentEvent.profileElements.includes(fieldName);
}

export const getFieldFromBubbleUser = (bubbleUser, label) => {
    if (!bubbleUser || !bubbleUser.content || !bubbleUser.content.length || !label) {
        return undefined;
    }
    return bubbleUser.content.find(item => item.label === label)
        ? bubbleUser.content.find(item => item.label === label).content
        : undefined;
}

export const richEditorSuggestions = [
    'FirstName',
    'EventName',
    'EventDate',
    'EventTime',
    'EventDuration',
    'NumSessions',
    'MinPerSession',
    'TeamSignature',
    'CheckinLink',
    'DirectoryLink',
    'TestWebcamLink',
    'CancelTicketLink',
    'SurveyLink',
    'FAQ'
];

export const saveEmail = async (template, body, subject, currentEvent, emailReminders, setEmailReminders) => {
    const event_id = currentEvent && currentEvent.eventId ? currentEvent.eventId : null;
    const email_type = template && template.email_type ? template.email_type : null;
    const email_category = template && template.email_category ? template.email_category : null;

    if (!event_id || !email_type || !subject) {
        return {
            error: 'Something went wrong'
        };
    }

    const updatedEventReminder = await store.dispatch(createUpdateEventReminderAction({
        event_id, email_type, subject, body, email_category
    }));

    if (!updatedEventReminder || !updatedEventReminder.status || updatedEventReminder.status !== 200) {
        console.error('updatedEventReminder has not been updated');
        return {
            error: updatedEventReminder.error || 'Something went wrong'
        };
    }

    const isNewEmailReminder = !emailReminders.find(item => item.email_type === updatedEventReminder.eventReminder.email_type);

    //update template
    if (!isNewEmailReminder) {
        setEmailReminders(emailReminders.map(item => {
            if (item.email_type !== updatedEventReminder.eventReminder.email_type) {
                return item;
            }
            return updatedEventReminder.eventReminder;
        }));
        return updatedEventReminder;
    }

    //adding new template
    if (isNewEmailReminder) {
        setEmailReminders([...emailReminders, updatedEventReminder.eventReminder]);
    }

    return updatedEventReminder;
}

export const getDateTime = (value = 0, param = 'days', currentEvent) => {
    const isEventDraft = currentEvent && currentEvent.publishedStatus && currentEvent.publishedStatus === 'draft';
    if (isEventDraft) {
        return '';
    }
    const startDateAndTimeTimezone = moment.tz(moment(currentEvent.startDateAndTime), currentEvent.timezone);
    const updatedStartDateAndTimeTimezone = startDateAndTimeTimezone.subtract(value, param);

    if (!startDateAndTimeTimezone.isValid()) {
        return '';
    }

    return `${updatedStartDateAndTimeTimezone.format('M/D')} @ ${startDateAndTimeTimezone.format('h:mma')} ${updatedStartDateAndTimeTimezone.format('z')}`;
}

export const getDummyTemplate = (type) => ({
    body: 'test body',
    email_type: type,
    isActive: true,
    subject: 'test subject',
    title: 'test title',
});

export const EVENT_IMAGE_MAX_SIZE = 5000000;//5Mb, not 5242880
export const AVATAR_OR_LOGO_IMAGE_MAX_SIZE = 2000000;
export const VB_IMAGE_MAX_SIZE = 2000000;
export const WRAPUP_SURVEY_MAX_QUESTIONS_FIVE_STAR = 10;
export const WRAPUP_SURVEY_MAX_QUESTIONS_PARAGRAPH = 5;
export const MAX_LENGTH_REACH_EDITOR_SYMBOLS = 5000;
export const MAX_LENGTH_SHORT_BIO = 1500;

export const hasHttpOrHttps = (str) => {
    const pattern =  new RegExp('(http(s?)):\/\/', 'i');
    return !!pattern.test(str);
}

export const isValidHttpUrl = (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

export const isValidLinkedinUrl = (str) => {
    const pattern = new RegExp('((https?:\\/\\/)?((www|\\w\\w)\\.)?linkedin\\.com\\/)((([\\w]{2,3})?)|([^\\/]+\\/(([\\w|\\d-&#?=])+\\/?){1,}))$','i');
    return !!pattern.test(str);
}

export const isValidTwitterUrl = (str) => {
    const pattern = new RegExp('^(?:https?:\\/\\/)?(?:www\\.)?(?:twitter|x)\\.com\\/([a-zA-Z0-9_]{1,15})\\/?$', 'i');
    return !!pattern.test(str);
}

export const sanitizeCSVData = (data) => (
    data.replace(/'/g, '')
    .replace(/"/g, '')
    .replace(/\\/g, '')
    .trim()
);

export const getSafeFieldFromObject = (errorResult, obj, ...args) => {
    return args.reduce((obj, level) => obj && obj[level], obj)
        ? args.reduce((obj, level) => obj && obj[level], obj)
        : errorResult;
}

export const handleBeforeInput = (editorState) => {
    const currentContentLength = editorState.getCurrentContent().getPlainText().length;

    if (currentContentLength > MAX_LENGTH_REACH_EDITOR_SYMBOLS - 1) {
        return 'handled';
    }
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const isEmptyObject = (obj) => {
    for(let key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

// https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
export const isChrome = () => {
    // please note,
    // that IE11 now returns undefined again for window.chrome
    // and new Opera 30 outputs true for window.chrome
    // but needs to check if window.opr is not undefined
    // and new IE Edge outputs to true now for window.chrome
    // and if not iOS Chrome check
    // so use the below updated condition
    const isChromium = window.chrome;
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof window.opr !== "undefined";
    const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    const isIOSChrome = winNav.userAgent.match("CriOS");

    if (isIOSChrome) {
        // is Google Chrome on IOS
        return true;
    }
    if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
    ) {
        // is Google Chrome
        return true;
    } else {
        // not Google Chrome
        return false;
    }
};

export const getCountByOppositeABTags = ({ eventRegistration, eventRegistrations, isPreviewMode, previewSelfEventTags, options }) => {
    const filteredUsers = []
    eventRegistrations.forEach(registration => {
        const userABTags = getABTagsByRegistration(registration.event_tags);

        const selfEventTags = isPreviewMode ?
            (previewSelfEventTags ? previewSelfEventTags.eventTags || {} : {}) :
            eventRegistration.eventTags;

        if (options.eventTags && selfEventTags['Group*']) {
            const isOpposite = userABTags && userABTags['Group*'].findIndex(({name}) => {
                return selfEventTags['Group*'] && selfEventTags['Group*'][0] && name === selfEventTags['Group*'][0].name
            }) === -1;

            if (isOpposite) {
                filteredUsers.push(registration)
            }
        } else {
            filteredUsers.push(registration);
        }
    });
    return filteredUsers.length;
}

export const clickableStyle = (isLink = false, light = false, green = false) => `
    color: ${light ? '#6cd8ff' : '#00B1F1'};
    cursor: pointer;
    &:hover {
        color: ${light ? '#3188b5' : '#0074af'};
        ${isLink ? 'text-decoration: underline;' : ''}
    }
    
    &:active {
        ${isLink ? 'text-decoration: underline;' : ''}
        color: ${green ? '#2a9b5a' : '#005b8f'};
    }
`;

export const clickableStyleWithoutColor = (isLink = false) => `
    cursor: pointer;
    &:hover {
        color: #00B1F1;
        ${isLink ? 'text-decoration: underline;' : ''}
    }
    
    &:active {
        ${isLink ? 'text-decoration: underline;' : ''}
        color: #0074af;
    }
`;

export const useProgressiveImage = src => {
    const [sourceLoaded, setSourceLoaded] = useState(null);

    useEffect(() => {
        if (!!src) {
            const img = new Image();
            img.src = src;
            img.onload = () => setSourceLoaded(src);
        }
    }, [src]);

    return sourceLoaded;
};

export const usePreloadingImages = (srcs = []) => {
    useEffect(() => {
        srcs.map(src => {
            if (!!src) {
                const img = new Image()
                img.src = src
                img.onload = () => {
                };
            }
        })
    }, [srcs])

    return null;
};

// function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = (componentImport, name) => {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
        );
        // try to import the component
        componentImport().then((component) => {
            window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
        });
    });
};


export const setVirtualBackground = (file, fileUrl, eventId) => {
    sessionStorage.setItem(`${eventId}-virtualBackground`, file);
    sessionStorage.setItem(`${eventId}-virtualBackground-url`, fileUrl);
}

export const getVirtualBackground = (eventId) => {
    return {
        file: sessionStorage.getItem(`${eventId}-virtualBackground`),
        fileUrl: sessionStorage.getItem(`${eventId}-virtualBackground-url`)
    }
}


export const getUniqueStr = (length = 20) => {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
};

export const isProduction = config.APP_ENV === 'production';
export const isDevelop = config.APP_ENV === 'develop';

export const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - getSyncDateNow()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - getSyncDateNow());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    const getReturnValues = (countDown) => {
        // calculate time left
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

        return {
            days, hours, minutes, seconds
        };
    };

    return getReturnValues(countDown);
};

export const is_touch_enabled = () => {
    return ( 'ontouchstart' in window ) ||
        ( navigator.maxTouchPoints > 0 ) ||
        ( navigator.msMaxTouchPoints > 0 );
}

export const useMessageTime = (time) => {
    const [updatedTime, setUpdatedTime] = useState(moment(time).fromNow());

    useEffect(() => {
        const interval = setInterval(() => {
            setUpdatedTime(moment(time).fromNow());
        }, 30000);
        return () => {
            clearInterval(interval);
        };
    }, []);


    return updatedTime;
}

export const getLabelForWebinar = (hosts = [], speakers = []) => {
    let result = '';
    let firstPath = '';
    let secondPath = '';
    if (hosts.length) {
        firstPath = `${hosts.length} ${hosts.length > 1 ? 'Hosts' : 'Host'}`
    }
    if (speakers.length) {
        secondPath = `${speakers.length} ${speakers.length > 1 ? 'Speakers' : 'Speaker'}`
    }
    if (firstPath && secondPath) {
        result = `${firstPath} & ${secondPath}`
    } else if (firstPath || secondPath) {
        result = `${firstPath ? firstPath : secondPath}`
    } else {
        result = 'None';
    }
    return result;
}

export const getDescriptionForEventFeatures = (feature) => {
    const featuresList = [
        { name: "ft_matching_tags", description: "Tag Matching" },
        { name: "ft_ab_tags", description: "A/B Matching" },
        { name: "ft_3way_rematch", description: "3-Way Rematch" },
        { name: "ft_hide_inactive_bulk_uploaded_attendees", description: "Hide inactive bulk uploaded attendees" },
        { name: "ft_attendee_directory", description: "Individual Matching" },
        { name: "ft_bulk_upload", description: "Bulk Upload" },
        { name: "ft_conversation_objectives", description: "Conversation Objectives" },
        { name: "ft_custom_ctas", description: "Wrapup Call-to-Action" },
        { name: "ft_duration_unlimited", description: "Unlimited Event Duration" },
        { name: "ft_extend_call", description: "Extend Calls" },
        { name: "ft_kickoff_videos", description: "Kickoff Video" },
        { name: "ft_wrapup_videos", description: "Wrapup Video" },
        { name: "ft_named_poc", description: "Named PoC" },
        { name: "ft_odd_person_out", description: "Odd-Person-Out" },
        { name: "ft_paid_tickets", description: "Paid Tickets" },
        { name: "ft_private_events", description: "Private Events" },
        { name: "ft_required_registration", description: "Ticket Required" },
        { name: "ft_sponsor", description: "Sponsors & Logos" },
        { name: "ft_weight_matching_tags", description: "Tag Weights" },
        { name: "ft_white_labelling", description: "Whitelabel" },
        { name: "ft_wrapup_feedback", description: "Custom Wrapup Survey" },
        { name: "ft_virtual_background", description: "Preset Virtual Background" },
        { name: "ft_integrations", description: "Integrations" },
        { name: "ft_sso", description: "Okta/SSO" },
        { name: "ft_webinar", description: "Webinar" }
    ];
    const result = featuresList.find(item => item.name === feature);

    if (result) {
        return result.description;
    }

    return 'Some feature';
}

export const checkActiveEventFeatures = (event, filteredFeatures, eventRegistrations) => {
    const resultList = [];
    let data;
    filteredFeatures.forEach(item => {
        switch (item.feature) {
            case "ft_attendee_directory":
                if (event.individualMatching || event.individual_matching)
                    resultList.push(item);
                break;
            case "ft_matching_tags":
                data = event.eventTags || event.event_tags;
                if (!isEmptyObject(data)) {
                    const string = typeof data === 'string' ?  data : JSON.stringify(data);
                    if (string.includes('weight":5') || string.includes('weight":10') || string.includes('weight":50')) {
                        resultList.push(item);
                    }
                }
                break;
            case "ft_conversation_objectives":
                if (event.objectives)
                    resultList.push(item);
                break;
            case "ft_paid_tickets":
                if ((event.eventTickets || event.event_tickets).filter(item => item.price > 0).length > 0)
                    resultList.push(item);
                break;
            case "ft_private_events":
                if ((event.privacySetting || event.privacy_setting) !== 'Public')
                    resultList.push(item);
                break;
            case "ft_required_registration":
                if (event.registrationRequired || event.registration_required)
                    resultList.push(item);
                break;
            case "ft_ab_tags":
                data = event.eventTags || event.event_tags;
                if (!isEmptyObject(data)) {
                    const string = typeof data === 'string' ?  data : JSON.stringify(data);
                    if (string.includes('weight":200')) {
                        resultList.push(item);
                    }
                }
                break;
            case "ft_3way_rematch":
                if ((event.sessionFormatRematch || event.session_format_rematch) !== "1toN")
                    resultList.push(item);
                break;
             case "ft_hide_inactive_bulk_uploaded_attendees":
                if (event.hideInactiveBulkUploadedAttendees || event.hide_inactive_bulk_uploaded_attendees)
                    resultList.push(item);
                break;
            case "ft_custom_ctas":
                const exitPageLinkUrl = event.exitPageLinkUrl || event.exit_page_link_url;
                const isDefault = ['videodev.meetaway.com/create-event', 'video.meetaway.com/create-event'].includes(exitPageLinkUrl);
                if ((event.exitPageButtonCta || event.exit_page_button_cta) !== 'Go' ||
                    (event.exitPagePrimaryCta || event.exit_page_primary_cta) !== 'Run a meetaway' || !isDefault)
                    resultList.push(item);
                break;
            case "ft_extend_call":
                if (!event.extendCallEnabled && !event.extend_call_enabled)
                    resultList.push(item);
                break;
            case "ft_kickoff_videos":
                if (event.hasKickoffVideo || event.has_kickoff_video)
                    resultList.push(item);
                break;
            case "ft_wrapup_videos":
                if (event.hasWrapupVideo || event.has_wrapup_video)
                    resultList.push(item);
                break;
            case "ft_odd_person_out":
                if ((event.sessionFormat || event.session_format) !== "1toN")
                    resultList.push(item);
                break;
            case "ft_sponsor":
                if ((event.sponsor_logos || (event.sponsorLogos && event.sponsorLogos.length)) ||
                    (eventRegistrations && eventRegistrations.length && eventRegistrations.filter(user => user.is_sponsor).length > 0))
                    resultList.push(item);
                break;
            case "ft_weight_matching_tags":
                data = event.eventTags || event.event_tags;
                if (!isEmptyObject(data)) {
                    const string = typeof data === 'string' ?  data : JSON.stringify(data);
                    if (string.includes('weight":10') || string.includes('weight":50'))
                        resultList.push(item);
                }
                break;
            case "ft_wrapup_feedback":
                if (!['1476125017716x120405806998618110', '1487014973905x788208973969621000'].includes(event.wrapupSurvey || event.wrapup_survey))
                    resultList.push(item);
                break;
            case "ft_virtual_background":
                if (event.default_virtual_background || event.defaultVirtualBackground)
                    resultList.push(item);
                break;
            case "ft_integrations":
                if ((event.eventbriteApiKey || event.eventbriteApiKeyCommonValue) ||
                    (event.apiSecretToken || event.apiSecretTokenCommonValue) ||
                    (event.eventbritePrivateToken || event.eventbritePrivateTokenCommonValue) ||
                    (event.account && (event.account.eventbrite_private_token || event.account.eventbrite_private_token_common_value ||
                        event.account.api_secret_token || event.account.api_secret_token_common_value)))
                    resultList.push(item);
                break;
            case "ft_webinar":
                if (event.webinarEnabled || event.webinar_enabled)
                    resultList.push(item);
                break;
            case "ft_duration_unlimited":
                if (event.duration > 60 || event.event_duration > 60)
                    resultList.push(item);
                break;
            case "ft_sso":
                if (event.account.okta_issuer)
                    resultList.push(item);
                break;
        }
    });

    return resultList;
}

export const checkEventAndAccountSubscription = ({getState, eventId}) => {
    const {
        controller: {currentEvent},
        home: { events: {upcomingEvents, templateEvents, priorEvents}, accounts },
        organizer: {featuresTierLevel},
        checkin: {eventRegistrations}
    } = getState();

    let copyWarning = false, eventSubscriptionPlan, accountSubscriptionPlan;
    let removedFeatures = [];
    let processedEvent = currentEvent;
    let userRegistrations = [];
    if (!processedEvent.eventId || eventId !== processedEvent.eventId) {
        const upcomingEvent = upcomingEvents.find(item => item.event === eventId);
        const templateEvent = templateEvents.find(item => item.event === eventId);
        const priorEvent = priorEvents.find(item => item.event === eventId);
        processedEvent = upcomingEvent || templateEvent || priorEvent;
        const account = accounts.find(item => item.account === processedEvent.account_id);

        processedEvent.eventSubscriptionPlan = processedEvent.account_subscription_plan;
        processedEvent.eventSubscriptionPlanId = processedEvent.account_subscription_plan_id;
        processedEvent.account = account;
        userRegistrations = processedEvent.eventRegistrations;
    } else {
        userRegistrations = eventRegistrations;
    }

    if (processedEvent.eventSubscriptionPlanId) {
        let accountSubscriptionTierLevel = +processedEvent.account.accountSubscriptionPlan.tier_level;
        let prevAccountSubscriptionTierLevel = +processedEvent.account.accountSubscriptionPlan.tier_level;
        eventSubscriptionPlan = processedEvent.eventSubscriptionPlan;
        accountSubscriptionPlan = processedEvent.account.accountSubscriptionPlan;

        if ((processedEvent.account.plan_status !== 'active' && processedEvent.account.plan_status !== 'pending-cancelation' && processedEvent.account.plan_status !== 'pending-downgrade')) {
            accountSubscriptionTierLevel = 1;
            prevAccountSubscriptionTierLevel = accountSubscriptionPlan.tier_level;
        }

        const features = featuresTierLevel
            .filter(item => {
                if (accountSubscriptionTierLevel !== prevAccountSubscriptionTierLevel) {
                    return !!eventSubscriptionPlan[item.feature];
                } else {
                    return !accountSubscriptionPlan[item.feature];
                }
            });

        const usesFeatures = checkActiveEventFeatures(processedEvent, features, userRegistrations);

        if (usesFeatures.length > 0) {
            copyWarning = true;
            removedFeatures = usesFeatures.map(item => getDescriptionForEventFeatures(item.feature));
        }
    }

    return {copyWarning, removedFeatures};
};

export const isValidUrl = urlString=> {
    try {
        return Boolean(new URL(urlString));
    }
    catch(e){
        return false;
    }
};

export const handlerTwitterLink = (data = '') => {
    let link;

    if (/^@/.test(data)) {
        link = 'https://twitter.com/' + data.substring(1);
    } else if (!data.includes('twitter.com') && !data.includes('x.com')) {
        link = 'https://twitter.com/' + data;
    } else {
        link = hasHttpOrHttps(data) ? data : `https://${data}`;
    }

    const isValid = isValidTwitterUrl(link);

    return {isValid, link: isValid ? link : data};
};

export const handlerLinkedinLink = (data = '') => {
    const link = hasHttpOrHttps(data) ? data : `https://${data}`;
    const isValid = isValidLinkedinUrl(link);

    return {isValid, link: isValid ? link : data};
};

export const getFullNameFromEventRegistrations = (userId) => {
    const {checkin: {eventRegistrations}} = store.getState();
    const targetRegistration = eventRegistrations.find(registration => registration.user_id === userId);
    const targetUserFirstName = _.get(targetRegistration, 'bubble_user.first_name', 'Unnamed');
    const targetUserLastName = _.get(targetRegistration, 'bubble_user.last_name', '');

    return `${targetUserFirstName} ${targetUserLastName}`;
};

export const getMatchingTagsForReports = ({event_tags, meet_tags}) => {
    const userTags = getTagsByRegistrationWithoutAB(event_tags) || {};
    const userMeetTags = getTagsByRegistrationWithoutAB(meet_tags) || {};
    const userABTags = getABTagsByRegistration(event_tags) || {};
    const userMeetABTags = getABTagsByRegistration(meet_tags) || {};

    const getPreparedTags = (userTags, userMeetTags)=> {
        const csvMeetTags = [];
        const csvMatchingTags = [];

        const tagsCategories = Object.keys(userTags);
        const tagsMeetCategories = Object.keys(userMeetTags);
        if (tagsCategories.length) {
            tagsCategories.forEach((category) => {
                csvMatchingTags[category] = (userTags[category] && userTags[category].length) ? userTags[category].map(({ name }) => name) : [];
            });
        }
        if (tagsMeetCategories.length) {
            tagsMeetCategories.forEach((category) => {
                csvMeetTags[category] = (userMeetTags[category] && userMeetTags[category].length) ? userMeetTags[category].map(({ name }) => name) : [];
            });
        }

        let matching_tags = "";
        let wants_to_meet_tags = "";

        if (csvMatchingTags) {
            const tagsMatchingCategories = Object.keys(csvMatchingTags);
            tagsMatchingCategories.forEach((category) => {
                if (category) matching_tags += `${category}: ${csvMatchingTags[category].join(", ")}; `;
            });
        }

        if (csvMeetTags) {
            const tagsMeetCategories = Object.keys(csvMeetTags);
            tagsMeetCategories.forEach((category) => {
                if (category) wants_to_meet_tags += `${category}: ${csvMeetTags[category].join(", ")}; `;
            });
        }

        return { matching_tags, wants_to_meet_tags };
    };

    return {
        tags: getPreparedTags(userTags, userMeetTags),
        abTags: getPreparedTags(userABTags, userMeetABTags),
    }
};
