import * as actionTypes from './actions'
import types from '../constans';

const initialState = {
    publisher: null,
    fullscreenPublisher: true,
    isVisitorAlone: true,
    joinIsClicked: false,
    showControls: true,
    needRecord: false,
    controls: {
        currentDisplayedControl: null // enum ['chat', 'settings']
    },
    connected: false,
    currentFullscreenVideoId: null,
    videoDevices: [],
    audioDevices: [],
    videoHasBeenConnected: false,
    partnerConnected: false,
    partnerClickedEndCall: false,
    meetingSubscribersCount: 0,
    myChoice: null,
    otherUserChoice: null,
    otherUserBChoice: null,
    otherUserSeenNextSessionPopup: false,
    otherUserBSeenNextSessionPopup: false,
    callAlreadyExtended: false,
    seenExtendCallPopup: false,
    publisherIsReady: false,
    endCallClicked: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SESSION_CONNECTED:
            return {...state, connected: true};
        case types.CLEAR_ROOM_INITIAL_PARAMS :
            return {...state, publisher: null,
                fullscreenPublisher: true,
                isVisitorAlone: true,
                joinIsClicked: false,
                showControls: true,
                needRecord: false,
                controls: {
                    ...state.controls,
                    currentDisplayedControl: null
                },
                connected: false,
                currentFullscreenVideoId: null,
                videoDevices: [],
                audioDevices: [],
                videoHasBeenConnected: false,
                partnerConnected: false,
                partnerClickedEndCall: false
            };
        case types.TOGGLE_FOOTER_CONTROLS_VISIBILITY:
            return {...state, showControls: action.showControls};
        case types.SET_CALL_RECORDED :
            return {...state, needRecord: action.needRecord};
        case types.SET_JOIN_IS_CLICKED :
            if (state.partnerConnected) {
                return {...state, joinIsClicked: true, videoHasBeenConnected: true};
            } else {
                return {...state, joinIsClicked: true};
            }
        case types.UNSET_VIDEO_HAS_BEEN_CONNECTED :
            return {...state, videoHasBeenConnected: false};
        case types.SET_IS_VISITOR_ALONE :
            return {...state, isVisitorAlone: action.isVisitorAlone};
        case types.CALL_ENDED :
            return {...state, joinIsClicked: false, connected: false};
        case types.SET_FULLSCREEN :
            return {...state, fullscreenPublisher: true};
        case types.UNSET_FULLSCREEN :
            return {...state, fullscreenPublisher: false};
        case types.HIDE_JOIN_BLOCK :
            return {...state, joinIsClicked: true};
        case types.PARTNER_CONNECTED :
            if (action.partnerConnected) {
                if (state.joinIsClicked) {
                    return {...state, partnerConnected: action.partnerConnected, partnerClickedEndCall: false, videoHasBeenConnected: true};
                } else {
                    return {...state, partnerConnected: action.partnerConnected, partnerClickedEndCall: false};
                }
            } else {
                return {...state, partnerConnected: action.partnerConnected };
            }
        case types.PARTNER_CLICKED_END_CALL :
            return {...state, partnerClickedEndCall: true};

        case types.MEMBER_JOIN_CONFERENCE :
            return {...state, meetingSubscribersCount: state.meetingSubscribersCount + 1};
        case types.MEMBER_LEAVE_CONFERENCE :
            return {...state, meetingSubscribersCount: state.meetingSubscribersCount === 0 ? 0 : state.meetingSubscribersCount - 1};
        case types.SET_MY_CHOICE :
            return {...state, myChoice: action.variant};
        case types.CALL_EXTENDED :
            return {...state, callAlreadyExtended: true};
        case types.SET_OTHER_USER_CHOICE :
            return {...state, otherUserChoice: action.variant};
        case types.SET_OTHER_USER_B_CHOICE :
            return {...state, otherUserBChoice: action.variant};
        case types.CLEAR_CHOICES :
            return {...state, myChoice: null, otherUserChoice: null, otherUserBChoice: null, callAlreadyExtended: false, seenExtendCallPopup: false};
        case types.SET_OTHER_USER_SEEN_NEXT_SESSION_POPUP :
            return {...state, otherUserSeenNextSessionPopup: true};
        case types.SET_OTHER_USER_B_SEEN_NEXT_SESSION_POPUP :
            return {...state, otherUserBSeenNextSessionPopup: true};
        case types.UNSET_USER_SEEN_NEXT_SESSION_POPUP :
            return {...state, otherUserSeenNextSessionPopup: false, otherUserBSeenNextSessionPopup: false};
        case types.SET_PUBLISHER_IS_READY:
            return {...state, publisherIsReady: action.flag};
        case types.SET_END_CALL_CLICKED:
            return {...state, endCallClicked: action.flag};
        case types.SET_ROOM_STORE:
            return {...state, ...action.store};
        default: return state
    }
}
